import React, { useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BaseButton, BaseButtonOutline } from 'bxcommon';
import { RequestContext } from 'bxcommon/context/Request.context';

import './Intro.scss';

export const Intro = props => {
  const { t } = useTranslation();
  const { request, cancelAmendment, hydrateList } = useContext(RequestContext);
  const onCancelAmendmentClick = async () => {
    await cancelAmendment(request.case_id);
    await hydrateList();
  };

  const { showIntro, isAmendment } = props;

  const dataIntro = {
    heading: isAmendment ? t('intro.amendment.heading') : t('intro.renewal.heading'),
    subheading: isAmendment ? t('intro.amendment.subheading') : t('intro.renewal.subheading'),
    start: isAmendment ? t('intro.amendment.start') : t('intro.renewal.start'),
    cancel: t('intro.cancel')
  };

  return (
    <div className={classnames('intro', { [`intro--renewal`]: !isAmendment })}>
      <h2 className="heading--subsection copy--color-dark intro__heading">{dataIntro.heading}</h2>
      <h3 className="copy intro__subheading">{dataIntro.subheading}</h3>
      <BaseButton
        iconRight
        icon="arrow-right"
        className="intro__button intro__button--main"
        onClick={() => showIntro(false)}
        intro
        iconWhite
      >
        {dataIntro.start}
      </BaseButton>
      <BaseButtonOutline intro className="intro__button base-button__intro--secondary" onClick={onCancelAmendmentClick}>
        {dataIntro.cancel}
      </BaseButtonOutline>
    </div>
  );
};

Intro.propTypes = {
  isAmendment: PropTypes.bool,
  showIntro: PropTypes.func
};
