import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { createRouteHelpers } from 'bxcommon/components/RouteWrapper/RouteWrapper';
import { RequestProvider } from 'bxcommon/context/Request.context';
import AdminLogin from './components/AdminLogin/AdminLogin';
import ListView from './components/ListView/ListView';
import DashboardView from './components/DashboardView/DashboardView';
import RequestDetails from './components/RequestDetails/RequestDetails';
import RequestForm from './components/RequestForm/RequestForm';
import UserLogin from './components/UserLogin/UserLogin';
import UserRegistration from './components/UserRegistration/UserRegistration';
import ThankYouAdvisor from './components/ThankYou/ThankYou';
import PublicRegistry from './components/PublicRegistry/PublicRegistry';

import UserAccountConfirmation from 'bxcommon/components/UserAccountConfirmation/UserAccountConfirmation';
import UserRegistrationConfirmation from 'bxcommon/components/UserRegistrationConfirmation/UserRegistrationConfirmation';
import UserForgotPassword from 'bxcommon/components/UserForgotPassword/UserForgotPassword';
import UserResetPassword from 'bxcommon/components/UserResetPassword/UserResetPassword';
import { BaseLoaderSpinner } from 'bxcommon';
import DocumentDownload from 'bxcommon/components/DocumentDownload/DocumentDownload';

import {
  initExistingToken,
  hydrateResources,
  warningToast,
  updateRequestField
} from 'bxcommon/store/actions';
import authHelper from 'bxcommon/helpers/auth.helper';
import store, { hydrateAdvisorList } from './store';
import AppConfig from 'bxcommon/providers/AppConfig';

const { PrivateRoute, UnauthenticatedRoute } = createRouteHelpers(store);

const ForgotPasswordComponent = React.createElement(UserForgotPassword, {isAdvisor: true});
const ResetPasswordComponent = React.createElement(UserResetPassword, {isAdvisor: true});

class Routes extends React.Component {
  state = {
    loaded: false,
    redirectTo: ''
  };

  async componentDidMount() {
    // Things need to be loaded for app to work
    if (!Object.keys(this.props.resources).length) {
      try {
        await this.props.hydrateResources('/client-advisors/metadata/');

        // if we have token and it's not expired set login action to true (create login only action)
        if (!this.props.user.loggedIn && !authHelper.isExpired) {
          await this.props.initExistingToken();

          // This admin route fetches data by itself on every redirect, we don't want to fetch it two times.
          if (!['/list', '/documents'].some(substr => ~this.props.location.pathname.indexOf(substr))) {
            await this.props.hydrateAdvisorList();
          }

          if (!this.props.user.details.is_admin && this.props.list.length) {
            await this.props.updateRequestField(this.props.list[0]);
          }

        } else if (authHelper.token && authHelper.isExpired) {
          this.props.warningToast(
           this.props.t('errors.sessionExpired'), this.props.t('errors.sessionExpiredTitle'),
          );
          authHelper.removeToken();
        }
      } finally {
        this.setState({loaded: true})
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const initRedirectCheck = props.meta.requestCompleted ||
      (props.request.case_id && !AppConfig.editStatuses.includes(props.request.status));
    let redirectTo;

    if (props.location.pathname === '/confirm-account') {
      authHelper.removeToken();
      return null;
    }

    if (props.user.details.is_admin) {
      redirectTo = '/dashboard';
    } else if (initRedirectCheck) {
      redirectTo = '/request-details';
    } else {
      redirectTo = '/request-form';
    }
    return state.redirectTo !== redirectTo ? { redirectTo } : null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      this.state.loaded ?
        <RequestProvider url="client-advisors">
          <Switch>
            <PrivateRoute
              path="/dashboard"
              adminOnly
              component={DashboardView}
            />
            <PrivateRoute
              load={[this.props.hydrateAdvisorList]}
              path="/list"
              adminOnly
              component={ListView}
            />
            <PrivateRoute
              loadIf={!this.props.list.length}
              path="/request-details/:caseId?"
              component={RequestDetails}
            />
            <PrivateRoute
              path="/request-form/confirmation/:caseId?"
              component={ThankYouAdvisor}
            />
            <PrivateRoute
              loadIf={!this.props.list.length}
              path="/request-form/:caseId?"
              component={RequestForm}
            />
            <PrivateRoute
              path="/documents/:uuid"
              component={DocumentDownload}
            />
            <UnauthenticatedRoute path="/register/confirmation" component={UserRegistrationConfirmation}/>
            <UnauthenticatedRoute path="/register" component={UserRegistration}/>
            <UnauthenticatedRoute path="/login" component={UserLogin}/>
            <UnauthenticatedRoute path="/admin" component={AdminLogin}/>
            <UnauthenticatedRoute path="/forgot-password" render={ForgotPasswordComponent}/>
            <UnauthenticatedRoute path="/reset-password" render={ResetPasswordComponent}/>
            <UnauthenticatedRoute path="/confirm-account" component={UserAccountConfirmation}/>
            <Route path="/public-registry" component={PublicRegistry}/>
            <Redirect to={this.state.redirectTo} />
          </Switch>
        </RequestProvider>
        : <BaseLoaderSpinner large />
    )
  }
}

const mapStateToProps = (state) => ({
  resources: state.resources,
  request: state.request,
  meta: state.requestMeta,
  list: state.list,
  user: state.user
});

export default withRouter(
  compose(
    connect(mapStateToProps, {
      updateRequestField,
      hydrateAdvisorList,
      hydrateResources,
      initExistingToken,
      warningToast
    }),
    withTranslation('common')
)(Routes));
