import React, {useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BaseIcon, BaseStatus } from 'bxcommon';
import { STATUS } from 'bxcommon/constants/status.constant';
import ListViewWrapper from 'bxcommon/components/ListViewWrapper/ListViewWrapper';
import { RequestContext } from 'bxcommon/context/Request.context';

import { AdvisorHeader } from '../AdvisorHeader/AdvisorHeader';
import { hydrateAdvisorList, removeFromAdvisorList } from '../../store';


const ListView = ({history, hydrateList, removeFromList, isAdmin, list}) => {
  const labels = ['lastUpdate', 'caseId', 'name', 'status', 'public', ''];

  const { clearForm, request } = useContext(RequestContext);

  useEffect(() => {
    if (!isAdmin) {
      history.push('/');
    }
  }, [isAdmin, history]);

  useEffect(() => {
    if (list.length && request.case_id) {
      clearForm();
    }
  }, [clearForm, list, request]);

  return (
    <AdvisorHeader>
      <ListViewWrapper
        labels={labels}
        editUrl={'request-form'}
        search={{queryName: 'query', hydrateList, placeholder: 'list.searchPlaceholder'}}
        detailsUrl={'request-details'}
        hydrateList={hydrateList}
        removeFromList={removeFromList}
        selectable
      >
        {item => (
          <>
            <td
              className="copy copy--color-dark"
              data-cypress="list-name"
            >
              {item.first_name} {item.last_name}
            </td>
            <td
              className="copy copy--color-dark"
              data-cypress="list-status"
            >
              <BaseStatus status={item.status}/>
            </td>
            <td
              className="copy copy--color-dark"
              data-cypress="list-published"
            >
              { item.status === STATUS.APPROVED &&
                <BaseIcon
                  icon={item.is_published ? 'yes' : 'no'}
                  data-cypress={item.is_published ? 'public' : 'not-public'}
                />
              }
            </td>
          </>
        )}
      </ListViewWrapper>
    </AdvisorHeader>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.user.details.is_admin,
  list: state.list
});

ListView.propTypes = {
  hydrateList: PropTypes.func,
  removeFromList: PropTypes.func
};

export default connect(
  mapStateToProps,
  {
    hydrateList: hydrateAdvisorList,
    removeFromList: removeFromAdvisorList,
  }
)(ListView);
