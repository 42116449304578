import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import AppConfig from 'bxcommon/providers/AppConfig';
import { STATUS } from 'bxcommon/constants/status.constant';
import { formatDate } from '../../helpers/formatDate.helper';

import './History.scss';

export const History = ({
  request_submission_date,
  request_approval_date,
  last_amendment_submission_date,
  last_amendment_approval_date,
  last_renewal_submission_date,
  last_renewal_approval_date,
  status,
  amendment
}) => {
  const { t } = useTranslation();
  const { dayMonthYearHourFormat } = AppConfig;
  const lastTypeAmendment =
    last_renewal_submission_date &&
    formatDate(last_renewal_submission_date, dayMonthYearHourFormat) >
      formatDate(last_amendment_submission_date, dayMonthYearHourFormat);

  const checkStatus = status =>
    Object.keys(STATUS)
      .find(key => STATUS[key] === status)
      .replace('_', ' ')
      .toLowerCase();

  const data = [
    {
      type: t('request.summary.headings.type'),
      submissionDate: t('request.summary.headings.submissionDate'),
      approvalDate: t('request.summary.headings.approvalDate'),
      status: t('request.summary.headings.status')
    },
    {
      type: t('request.summary.headings.typeInitial'),
      submissionDate: formatDate(request_submission_date, dayMonthYearHourFormat),
      approvalDate: formatDate(request_approval_date, dayMonthYearHourFormat),
      status: checkStatus(status)
    },
    amendment && {
      type: lastTypeAmendment ? t('request.summary.headings.typeRenewal') : t('request.summary.headings.typeAmendment'),
      submissionDate: formatDate(
        lastTypeAmendment ? last_renewal_submission_date : last_amendment_submission_date,
        dayMonthYearHourFormat
      ),
      approvalDate:
        amendment.status === STATUS.APPROVED
          ? formatDate(
              lastTypeAmendment ? last_renewal_approval_date : last_amendment_approval_date,
              dayMonthYearHourFormat
            )
          : '-',
      status: checkStatus(amendment.status)
    }
  ];

  return (
    <div className="history">
      <table>
        <tbody>
          {data.map(
            (history, index) =>
              history !== null && (
                <tr
                  className={classnames(
                    'history__row',
                    `history__row--${history.status.toLocaleLowerCase().replace(' ', '-')}`
                  )}
                  key={history.type}
                >
                  {Object.values(history).map((main, idx) =>
                    index === 0 ? (
                      <th className="history__cell--title" key={idx}>
                        <div>{main}</div>
                      </th>
                    ) : (
                      <td className="history__cell--values" key={idx}>
                        <div>{main}</div>
                      </td>
                    )
                  )}
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

History.propTypes = {
  request_submission_date: PropTypes.string,
  request_approval_date: PropTypes.string,
  last_amendment_submission_date: PropTypes.string,
  last_amendment_approval_date: PropTypes.string,
  last_renewal_submission_date: PropTypes.string,
  last_renewal_approval_date: PropTypes.string,
  status: PropTypes.number,
  amendment: PropTypes.object
};
