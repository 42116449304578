import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BaseBox, RequestStepWrapper, SummaryWrapper } from 'bxcommon';
import { FormWrapper } from 'bxcommon/components/FormWrapper/FormWrapper';
import { RequestContext } from 'bxcommon/context/Request.context';
import { ClientAdvisorBadge } from 'bxcommon/components/ClientAdvisorBadge/ClientAdvisorBadge';
import { STATUS } from 'bxcommon/constants/status.constant';
import { isFormEditable } from 'bxcommon/helpers/form.helper';
import { registryInitCheck } from '../../common/common.helpers';
import {
  togglePublish,
  updateAdditionalRecipientsWhenClientAdvisorApproved
} from '../../store/actions/request.actions';
import { successToast, errorToast } from 'bxcommon/store/actions';
import AppConfig from 'bxcommon/providers/AppConfig';
import { AdvisorHeader } from '../AdvisorHeader/AdvisorHeader';
import { FORM_VARIANT } from '../../constants/form.constant';
import { BasicInformation, CareerInformation, BuisnessInformation, AdditionalInformation } from 'components/Summary';
import { History } from 'components/History/History';

import 'bxcommon/components/SummaryHelpers/SummaryHelpers.scss';
import './RequestDetails.scss';

export const RequestDetails = props => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const {
    request,
    updateRequestField,
    list,
    setLoading,
    resources: { payment_methods },
    metadata: { requestCompleted }
  } = useContext(RequestContext);

  const isClientAdvisorBadgeVisible = request.status === STATUS.APPROVED && request.badge_png_file;

  useLayoutEffect(() => {
    if (requestCompleted) return;

    if (
      !request.case_id ||
      isFormEditable(request.status) ||
      (request.amendment && AppConfig.editStatuses.includes(request.amendment.status))
    ) {
      props.history.push(`/request-form/${props.user.is_admin ? request.case_id : ''}`);
    }
  }, [props.history, props.user.is_admin, request.case_id, request.status, requestCompleted, request.amendment]);

  // Needed only for hydration data on entry straight to the /caseID url/  when in admin mode
  useEffect(() => {
    registryInitCheck(props.match, props.user, updateRequestField, list, request);
  }, [props.match, props.user, updateRequestField, list, request]);

  const togglePublish = async e => {
    setLoading(true);

    try {
      await props.togglePublish(e, request.case_id);

      props.successToast(
        t(`request.details.${e ? 'togglePublish' : 'toggleUnpublish'}.success`),
        common('successToastHeading')
      );
    } catch (_) {
      props.errorToast(
        t(`request.details.${e ? 'togglePublish' : 'toggleUnpublish'}.error`),
        common('errorToastHeading')
      );
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    comment: request.comment || '',
    additional_recipients: request.additional_recipients || '',
    payment: {
      method: 1,
      voucher_code: '',
      express_process: false,
      accepted_additional_fee_agreement: false,
      status: request.payment ? request.payment.status : null
    }
  };

  const categories = {
    basicInformation: t('request.summary.headings.basicInformation'),
    careerInformation: t('request.summary.headings.careerInformation'),
    companyInformation: t('request.summary.headings.companyInformation'),
    additionalInformation: t('request.summary.headings.additionalInformation'),
    downloadQr: t('request.summary.headings.downloadQr'),
    history: t('request.summary.headings.history')
  };

  const { REQUEST_DETAILS } = FORM_VARIANT;
  const [activeCategory, setActiveCategory] = useState(categories.basicInformation);

  return (
    <AdvisorHeader>
      <FormWrapper isAdvisor>
        <RequestStepWrapper
          isAdvisor
          togglePublish={togglePublish}
          formVariant={REQUEST_DETAILS}
          setActiveCategory={setActiveCategory}
          activeCategory={activeCategory}
          categories={categories}
        >
          <div className="request-details">
            {/* Basic information */}
            {activeCategory === categories.basicInformation && <BasicInformation {...props} title />}

            {/* Career information */}
            {activeCategory === categories.careerInformation && <CareerInformation {...props} />}

            {/* Business information */}
            {activeCategory === categories.companyInformation && <BuisnessInformation {...props} title />}

            {/* Additional information */}
            {activeCategory === categories.additionalInformation && payment_methods && initialValues.payment && (
              <AdditionalInformation {...props} />
            )}

            {/* History */}
            {activeCategory === categories.history && (
              <SummaryWrapper heading={categories.history} {...props}>
                <BaseBox borderless className="base-summary__section history">
                  <History {...request} />
                </BaseBox>
              </SummaryWrapper>
            )}

            {/* Download Qr code */}
            {activeCategory === categories.downloadQr && (
              <SummaryWrapper heading={t('request.summary.headings.downloadQr')} {...props}>
                <BaseBox borderless className="base-summary__section">
                  {isClientAdvisorBadgeVisible && (
                    <ClientAdvisorBadge
                      clientId={request.case_id}
                      href={request.badge_zip_file}
                      image={request.badge_png_file}
                      variant={REQUEST_DETAILS}
                    />
                  )}
                </BaseBox>
              </SummaryWrapper>
            )}
          </div>
        </RequestStepWrapper>
      </FormWrapper>
    </AdvisorHeader>
  );
};

const mapStateToProps = state => ({
  user: state.user.details
});

export default connect(
  mapStateToProps,
  {
    togglePublish,
    successToast,
    errorToast,
    updateAdditionalRecipientsWhenClientAdvisorApproved
  }
)(RequestDetails);
