import React from 'react';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import axios from 'axios';

import store from './store'

import { FooterComponent, ErrorFallback } from 'bxcommon';
import BxBrowserRouter from 'bxcommon/BxBrowserRouter';

import Toaster from 'bxcommon/components/Toaster/Toaster';
import { ModalProvider } from 'bxcommon/context/Modal.context';
import Routes from './Routes';
import responseInterceptor from 'bxcommon/interceptors/response.interceptor';
import authHelper from 'bxcommon/helpers/auth.helper';
import errorsService from 'bxcommon/helpers/errors.helper';
import publicRoutes from './common/public-routes';

import 'bxcommon/styles/main.scss';
import './App.scss';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

responseInterceptor(store, publicRoutes);
authHelper.init('registry');
errorsService.init(store);

const Layout = () => (
  <div className="primary-layout">
    <div className="primary-layout-content">
      <Toaster />
      <Routes />
    </div>
    <FooterComponent />
  </div>
);

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Provider store={store}>
        <BxBrowserRouter>
          <ModalProvider>
            <Layout />
          </ModalProvider>
        </BxBrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
