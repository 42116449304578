import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { searchForLabel } from 'bxcommon/helpers/form.helper';
import { SummaryFilesList } from 'bxcommon/components/SummaryHelpers/SummaryFiles';
import { RequestContext } from 'bxcommon/context/Request.context';
import { BaseBox, BaseBoxHeading, SummaryInput, summaryValuesIterator } from 'bxcommon';

import { type_of_employment } from '../RequestForm/CompanyInformation';

export const CompanyInformationDetails = props => {
  const { t } = useTranslation();
  const { company } = props;
  const {
    resources: { countries, ombudsman_options }
  } = useContext(RequestContext);

  return (
    <div>
      <BaseBox borderless className="base-summary__section">
        <BaseBoxHeading className="base-summary__subheading">
          {t('request.summary.headings.companyInformation')}
        </BaseBoxHeading>

        <ul className="base-summary__content">
          <SummaryInput
            name={t('request.form.companyInformation.employmentRelationship')}
            data-cypress="summary-type-of-employment"
          >
            {searchForLabel(type_of_employment, company.type_of_employment)}
          </SummaryInput>
          <SummaryInput name={t('request.form.companyInformation.company_name')} data-cypress="summary-company-name">
            {company.name}
          </SummaryInput>
          <SummaryInput name={t('request.summary.address')}>
            {(company.address_line_1 || company.address_line_1) && (
              <span className="base-summary__address">
                <span className="base-summary--margin" data-cypress="summary-company-address-line-1">
                  {company.address_line_1}
                </span>
                <span className="base-summary--margin" data-cypress="summary-company-address-line-2">
                  {company.address_line_2}
                </span>
              </span>
            )}
          </SummaryInput>
          <SummaryInput
            name={`
              ${t('request.form.common.postcode')} /
              ${t('request.form.common.city')}`}
            data-cypress="summary-company-city"
          >
            {(company.postcode || company.city) && (
              <>
                {company.postcode} {company.city}
              </>
            )}
          </SummaryInput>

          <SummaryInput name={t('request.form.common.country')} data-cypress="summary-company-country">
            {searchForLabel(countries, company.country)}
          </SummaryInput>
        </ul>
      </BaseBox>
      <BaseBox borderless className="base-summary__section">
        <BaseBoxHeading className="base-summary__subheading">
          {t('request.summary.headings.jobDescription')}
        </BaseBoxHeading>

        <ul className="base-summary__content">
          <SummaryInput
            name={t('request.form.jobDescription.job_function')}
            data-cypress="summary-company-job-function"
          >
            {company.job_function}
          </SummaryInput>
          <SummaryInput
            name={t('request.form.jobDescription.job_position')}
            data-cypress="summary-company-job-position"
          >
            {company.job_position}
          </SummaryInput>
        </ul>
      </BaseBox>
      <BaseBox borderless className="base-summary__section">
        <BaseBoxHeading className="base-summary__subheading">
          {t('request.summary.headings.associatedOmbudsman')}
        </BaseBoxHeading>
        {summaryValuesIterator(ombudsman_options.data, company.ombudsman_offices)}
      </BaseBox>
      <BaseBox borderless className="base-summary__section">
        <BaseBoxHeading className="base-summary__subheading">{t('request.summary.headings.documents')}</BaseBoxHeading>
        <SummaryInput name={t('request.summary.confirmationOfEmployment')}>
          <SummaryFilesList files={company.confirmation_of_employment_files} download />
        </SummaryInput>
        <SummaryInput name={t('request.summary.liabilityInsuranceOrCoverage')}>
          <SummaryFilesList files={company.liability_insurance_files} download />
        </SummaryInput>
      </BaseBox>
    </div>
  );
};
