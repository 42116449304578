import React, {useState} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PublicRegistryResult } from './PublicRegistryResult';
import { PublicRegistrySearch } from './PublicRegistrySearch';
import { AdvisorHeader } from '../AdvisorHeader/AdvisorHeader';

import { getRegistryResult, warningToast } from 'bxcommon/store/actions';

import './PublicRegistry.scss'


const PublicRegistry = (props) => {
  const { t } = useTranslation();
  const {
    getRegistryResult,
    warningToast,
    countries,
    fieldsOfActivity,
    result,
    predefinedCertificates,
    degrees,
    ombudsmanOffices,
    finsaCertificates
  } = props;

  const [loading, setLoading] = useState(false);
  const [searchCompleted, setSearchCompleted] = useState(false);

  const scrollToResult = () => {
    // top 210 to hide heading and description. Showing the result while still allowing to search again.
    window.scrollTo({ top: 210, left: 0, behavior: 'smooth' });
  };

  const handleSubmit = async(data) => {
    try {
      setLoading(true);
      await getRegistryResult(data);
      setSearchCompleted(true);
      scrollToResult();
    } catch (_) {
      warningToast(t('publicRegistry.errorContent'), t('publicRegistry.errorTitle'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdvisorHeader hideControls>
      <div className="container">
        <div className="row">
          <div className="col-2" />
          <div className="col-8">
            <div className="public-registry">
              <h1
                className="heading heading--primary heading--color-primary public-registry__heading"
                data-cypress="public-registry-heading"
              >
                {t('publicRegister')}
              </h1>
              <p
                className="copy copy--color-dark public-registry__description"
                data-cypress="public-registry-description"
              >
                {t('publicRegistry.description')}
              </p>
              <PublicRegistrySearch
                onSubmit={handleSubmit}
                loading={loading}
              />
              <PublicRegistryResult
                result={result}
                countries={countries}
                predefinedCertificates={predefinedCertificates.data}
                degreeData={degrees.data}
                ombudsmanOffices={ombudsmanOffices.data}
                fieldsOfActivity={fieldsOfActivity.data}
                finsaCertificates={finsaCertificates.data}
                searchCompleted={searchCompleted}
              />
            </div>
          </div>
          <div className="col-2" />
        </div>
      </div>
    </AdvisorHeader>
  )
};

const mapStateToProps = (state) => ({
  countries: state.resources.countries,
  ombudsmanOffices: state.resources.ombudsman_options,
  degrees: state.resources.education,
  predefinedCertificates: state.resources.predefined_certificates,
  finsaCertificates: state.resources.finsa_certificates,
  fieldsOfActivity: state.resources.fields_of_activity,
  result: state.registry.result
});

export default connect(mapStateToProps, {getRegistryResult, warningToast})(PublicRegistry);

