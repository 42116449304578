import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, FastField } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';


import { AdvisorHeader } from '../AdvisorHeader/AdvisorHeader';
import { HalfPageLayout } from 'bxcommon/components/HalfPageLayout/HalfPageLayout';
import LoginMessages from 'bxcommon/components/LoginMessages/LoginMessages';
import { BaseBox, BaseBoxHeading, BaseButtonFill, BaseCheckbox, BaseInput, BaseRadioGroup } from 'bxcommon';
import { createInput, passwordValidator, requiredStringValidator } from 'bxcommon/helpers/form.helper';
import { Captcha } from 'bxcommon/components/Captcha/Captcha';
import { register } from 'bxcommon/misc/requests';
import { successToast } from 'bxcommon/store/actions';
import errorsService, { REGISTRATION_ERRORS } from 'bxcommon/helpers/errors.helper';

const UserRegistration = ({history, successToast}) => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const [captchaError, setCaptchaError] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);

  const initialFormValues = {
    title: '',
    first_name: '',
    last_name: '' ,
    email: '',
    password: '',
    confirm_password: '',
    consent: false
  };

  const validationSchema = Yup.object().shape({
    title: requiredStringValidator(),
    first_name: requiredStringValidator(),
    last_name: requiredStringValidator(),
    email: requiredStringValidator().email(common('errors.email')),
    password: passwordValidator(),
    confirm_password: passwordValidator()
      .oneOf([Yup.ref('password'), null], common('errors.passwordsDontMatch')),
    consent: Yup.boolean().oneOf([true], common('errors.requiredField'))
  });

  const onSubmit = async(formData) => {
    if (!captchaError) {
      formData.last_login = new Date();

      try {
        setRegisterLoading(true);
        await register(formData);

        successToast(
          common('register.success.successDescription'),
          common('register.success.successTitle')
        );
        setRegisterLoading(false);
        history.push('/login');
      } catch (error) {
        errorsService.handleErrorToast(error, REGISTRATION_ERRORS);
        setRegisterLoading(false);
      }
    }
  };

  const titleOptions = t(`register.titleOptions`, {returnObjects: true}).map(t => ({id: t, name: t}));

  const RightContent = () => (
    <div>
      <div className="user-common-view__title">
        <h1 className="heading heading--primary heading--color-primary">
          {common('register.heading')}
        </h1>
        <div>
          {common('register.subheading')}
        </div>
      </div>
      <Formik
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <BaseBox
            className="user-common-view__box"
            borderless
          >
            <BaseBoxHeading>{common('register.accountDetails')}</BaseBoxHeading>
            <Form className="form-container">
              <div className="row">
                <div className="col-1"/>
                <div className="col-10">
                  <div className="row">
                    <FastField
                      component={BaseRadioGroup}
                      name="title"
                      options={titleOptions}
                      data-cypress="title"
                    />
                  </div>
                  <div className="row">
                    {createInput('first_name', common('register.firstName'))}
                  </div>
                  <div className="row">
                    {createInput('last_name', common('register.lastName'))}
                  </div>
                  <div className="row">
                    {createInput('email', t('register.email'))}
                  </div>
                  <div className="row">
                    <FastField
                      component={BaseInput}
                      name="password"
                      type="password"
                      label={common('register.password')}
                      inputHint={common('passwordHint')}
                      data-cypress="password"
                    />
                  </div>
                  <div
                    className="row"
                    data-cypress="confirm-password-wrapper"
                  >
                    <FastField
                      component={BaseInput}
                      name="confirm_password"
                      type="password"
                      label={common('register.confirmPassword')}
                      data-cypress="confirm_password"
                    />
                  </div>
                  <div className="row user-common-view__consent">
                    <FastField
                      component={BaseCheckbox}
                      name="consent"
                      checked={props.values.consent}
                      data-cypress="consent"
                    >
                      <Trans i18nKey="register.consent" ns="common">
                        <a
                          href="https://www.regservices.ch/?download=6313"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link--underlined"
                        >
                          User Agreement
                        </a>
                        <a
                          href="https://www.regservices.ch/en/privacy-policy/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link--underlined"
                        >
                          Privacy Policy
                        </a>
                      </Trans>
                    </FastField>
                  </div>

                  {!window.top.Cypress && (
                    <Captcha
                      validateOnSubmitCount={props.submitCount}
                      setCaptchaError={setCaptchaError}
                      captchaError={captchaError}
                    />
                  )}

                  <div className="row user-common-view__submit">
                    <div className="col-12">
                      <BaseButtonFill
                        icon="arrow-right"
                        iconRight
                        full
                        type="submit"
                        loading={registerLoading}
                        data-cypress="register-button"
                      >
                        {common('register.submitButton')}
                      </BaseButtonFill>
                    </div>
                  </div>
                </div>
                <div className="col-1"/>
              </div>
            </Form>
          </BaseBox>
        )}
      </Formik>
    </div>
  );

  return (
    <AdvisorHeader>
      <div className="user-common-view__content">
        <HalfPageLayout
          leftContent={<LoginMessages />}
          rightContent={<RightContent />}
        />
      </div>
    </AdvisorHeader>
  )
};

export default connect(null, { successToast })(UserRegistration);
