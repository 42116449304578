import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { FastField, FieldArray, Form } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import moment from 'moment';
import {
  createInput,
  createDatePicker,
  createCheckboxGroup,
  createSelect,
  createUploadBox,
  createCheckbox,
  isAnyDegreeSelected,
  filterInactiveMetadataForOptions
} from 'bxcommon/helpers/form.helper';
import { asyncForEach, textBreaker } from 'bxcommon/helpers/common.helpers';
import { RequestContext } from 'bxcommon/context/Request.context';
import AppConfig from 'bxcommon/providers/AppConfig';
import config from '../../registry.config';
import { formatDate } from 'registry/src/helpers/formatDate.helper';
import { getEarliestDate } from 'bxcommon/helpers/earliestDate';
import { BaseBox, BaseBoxHeading, BaseHint, BaseTextArea, SummaryInput } from 'bxcommon';
import { SummaryFilesList } from 'bxcommon/components/SummaryHelpers/SummaryFiles';
import {
  BasicInformation,
  BuisnessInformation,
  FieldsOfActivity,
  AcademicDegrees,
  CodeOfConduct,
  FieldsOfActivityList
} from 'components/Summary';
import { CompanyInformation } from './CompanyInformation';
import { STEP_VARIANT } from 'constants/steps.constant';

const { maxFileSize } = config;

export const Step = props => {
  const { user, activeStep, values, errors, touched, editForm, isRenewal, isAmendment } = props;
  const { t } = useTranslation();
  const {
    request,
    formRef,
    removeFile,
    saveStep,
    resources: {
      predefined_certificates,
      finsa_certificates,
      ombudsman_options,
      fields_of_activity,
      countries,
      dialcodes,
      education: degrees
    },
    FILE_UPLOAD_TYPES
  } = useContext(RequestContext);

  const {
    PERSONAL_INFORMATION,
    FIELDS_OF_ACTIVITY,
    PROFFESSIONAL_ACADEMIC_DEGREES,
    PROOF_OF_CODE_OF_CONDUCT,
    COMPANY_INFORMATION,
    SUMMARY
  } = STEP_VARIANT;

  const [, disableFooter] = useState(false);
  const renderUploadBox = createUploadBox(disableFooter);

  const removeFiles = async values => {
    await asyncForEach(values, async file => {
      await removeFile(file.id);
    });
  };

  const cleanFieldsAndFiles = async (fieldName, index, value) => {
    const formik = formRef.current;
    const values = formik.state.values;
    switch (fieldName) {
      case 'education.degrees_temp.type':
        const degrees = JSON.parse(JSON.stringify(values.education.degrees_temp));
        degrees.type[index] = value;
        if (!isAnyDegreeSelected(degrees)) {
          await removeFiles(values.professional_or_academic_degree_files);
          formik.setFieldValue('professional_or_academic_degree_files', []);
        }
        break;
      case 'education.has_predefined_certificates':
        await removeFiles(values.predefined_certificate_files);
        formik.setFieldValue('predefined_certificate_files', []);
        formik.setFieldValue('education.predefined_certificates', []);
        break;
      case 'education.has_additional_certificates':
        await removeFiles(values.additional_certificate_files);
        formik.setFieldValue('additional_certificate_files', []);
        formik.setFieldValue('education.additional_certificates', '');
        break;
      case 'education.has_employer_confirmation':
        await removeFiles(values.employer_confirmation_files);
        formik.setFieldValue('employer_confirmation_files', []);
        break;
      case 'education.has_finsa_certificates':
        await removeFiles(values.finsa_certificate_files);
        formik.setFieldValue('finsa_certificate_files', []);
        formik.setFieldValue('education.finsa_certificates', []);
        break;
      case 'liability_insurance_files':
      case 'confirmation_of_employment_files':
        await removeFiles(values.companies[index][fieldName]);
        formik.setFieldValue(`companies.${index}.${fieldName}`, []);
        break;
      default:
        return;
    }
  };

  const { dotsDateFormat } = AppConfig;
  const dateValid =
    request.request_approval_date && formatDate(new Date(request.request_approval_date), dotsDateFormat);

  switch (activeStep) {
    case PERSONAL_INFORMATION:
      return (
        <>
          {/* Basic information */}
          {editForm ? (
            <BaseBox borderless data-cypress="request-form-box-personal" lifted>
              <BaseBoxHeading className="request-wrapper__main-heading">
                {t('request.form.personalInformation.heading')}
              </BaseBoxHeading>
              <div className="col-12">
                <div className="row">{createInput('first_name', t('request.form.personalInformation.first_name'))}</div>
                <div className="row">{createInput('last_name', t('request.form.personalInformation.last_name'))}</div>
                <div className="row">
                  {createInput(
                    'email',
                    t('request.form.personalInformation.email'),
                    Boolean(!user.is_admin || request.case_id)
                  )}
                </div>
                <div className="row">{createInput('address_line_1', t('request.form.common.address_line_1'))}</div>
                <div className="row">{createInput('address_line_2', t('request.form.common.address_line_2'))}</div>
                <div className="row">
                  <div className="col-4">{createInput('postcode', t('request.form.common.postcode'))}</div>
                  <div className="col-8">{createInput('city', t('request.form.common.city'))}</div>
                </div>
                <div className="row">
                  {createSelect({
                    name: 'country',
                    label: t('request.form.common.countryOfDomicile'),
                    options: countries,
                    placeholder: t('request.form.personalInformation.countryPlaceholder')
                  })}
                </div>
                <div className="row request-form--with-hint">
                  {createInput('alternative_email', t('request.form.personalInformation.alternative_email'))}
                  <BaseHint>{textBreaker(t('request.form.hints.emailAlternative'))}</BaseHint>
                </div>
                <div className="row request-form--align-end">
                  <div className="col-6">
                    {createSelect({
                      name: 'phone_number_dial_code',
                      label: t('request.form.personalInformation.phone_number'),
                      options: dialcodes,
                      customKeys: { label: 'dial', value: 'id' }
                    })}
                  </div>
                  <div className="col-6">{createInput('phone_number')}</div>
                </div>
                <div className="row request-form--align-end">
                  <div className="col-6">
                    {createSelect({
                      name: 'work_phone_number_dial_code',
                      label: t('request.form.personalInformation.work_phone_number'),
                      options: dialcodes,
                      customKeys: { label: 'dial', value: 'id' }
                    })}
                  </div>
                  <div className="col-6">{createInput('work_phone_number')}</div>
                </div>
                <div className="row">
                  {createDatePicker(
                    'date_of_birth',
                    t('request.form.personalInformation.date_of_birth'),
                    false,
                    v => v > moment().subtract(1, 'day')
                  )}
                </div>
                <div className="row">
                  {createSelect({
                    name: 'country_of_citizenship',
                    label: t('request.form.personalInformation.country_of_citizenship'),
                    options: countries
                  })}
                </div>
                <div className="row">
                  {createSelect({
                    name: 'additional_country_of_citizenship',
                    label: t('request.form.personalInformation.additional_country_of_citizenship'),
                    options: countries,
                    allowClear: true
                  })}
                </div>
                <div className="row">
                  {createInput('place_of_origin', t('request.form.personalInformation.place_of_origin'))}
                </div>

                <div className="request-form--with-hint">
                  {renderUploadBox({
                    name: 'passport_files',
                    title: t('request.form.uploadDocuments.passportFiles'),
                    conditions: t('request.form.uploadDocuments.fileDefaults'),
                    type: FILE_UPLOAD_TYPES.PASSPORT_FILE,
                    maxFileSize,
                    isRenewal: isRenewal,
                    config: {
                      pdfOnly: false
                    }
                  })}
                  <BaseHint>{t('request.form.hints.passportFiles')}</BaseHint>
                </div>
                <div className="request-form--with-hint">
                  {renderUploadBox({
                    name: 'cv_files',
                    title: t('request.form.uploadDocuments.cvFiles'),
                    conditions: t('request.form.uploadDocuments.fileDefaults'),
                    type: FILE_UPLOAD_TYPES.CV_FILE,
                    maxFileSize,
                    isRenewal: isRenewal,
                    config: {
                      pdfOnly: false
                    }
                  })}
                  <BaseHint>{t('request.form.hints.cvFiles')}</BaseHint>
                </div>
                <div className="request-form--with-hint request-form--criminal-record">
                  {renderUploadBox({
                    name: 'criminal_record_extract_files',
                    title: t('request.form.uploadDocuments.criminalRecordExtract'),
                    conditions: t('request.form.uploadDocuments.fileDefaults'),
                    type: FILE_UPLOAD_TYPES.CRIMINAL_RECORD_EXTRACT_FILE,
                    maxFileSize,
                    isRenewal: isRenewal,
                    config: {
                      pdfOnly: false
                    }
                  })}
                  <BaseHint>{t('request.form.hints.criminalRecordExtract')}</BaseHint>
                </div>
              </div>
            </BaseBox>
          ) : (
            <BasicInformation {...props} banner summary isRenewal={isRenewal} />
          )}
        </>
      );
    case FIELDS_OF_ACTIVITY:
      return (
        <>
          {/* Fields of activity */}
          {editForm ? (
            <BaseBox borderless data-cypress="request-form-box-fields-of-activity" lifted>
              <BaseBoxHeading className="request-wrapper__main-heading">
                {t('request.form.fieldsOfActivity.heading')}
              </BaseBoxHeading>

              <div className="form-container">
                <div className="row request-form--with-hint">
                  {createCheckboxGroup(
                    'fields_of_activity',
                    '',
                    filterInactiveMetadataForOptions({
                      options: fields_of_activity.data,
                      requestValues: request.fields_of_activity
                    }),
                    values.fields_of_activity
                  )}
                  <BaseHint className="base-hint--for-full-width-form-box">
                    <Trans i18nKey="request.form.hints.fieldsOfActivity">
                      <a
                        href="https://www.regservices.ch/?download=5751"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link--underlined"
                      >
                        Art. 3 FinSA
                      </a>
                    </Trans>
                  </BaseHint>
                </div>
              </div>
            </BaseBox>
          ) : (
            <FieldsOfActivity {...props} summary />
          )}
        </>
      );
    case PROFFESSIONAL_ACADEMIC_DEGREES:
      return (
        <>
          {/* Professional education*/}
          {editForm ? (
            <BaseBox borderless data-cypress="request-form-box-proof-of-required-knowledge" lifted>
              <div className="form-container">
                <BaseBoxHeading className="request-wrapper__main-heading">
                  {t('request.summary.headings.academicDegrees')}
                </BaseBoxHeading>
                <div className="row request-form--with-hint request-form__degrees-group">
                  <div className="col-12">
                    {degrees &&
                      degrees.data &&
                      degrees.data.length > 0 &&
                      degrees.data.map((degree, index) => (
                        <div key={index}>
                          {createCheckbox(
                            `education.degrees_temp.type.${degree.id}`,
                            values.education.degrees_temp.type[degree.id],
                            value => cleanFieldsAndFiles('education.degrees_temp.type', degree.id, value),
                            degree.name
                          )}
                          {values.education.degrees_temp.type[degree.id] &&
                            createInput(`education.degrees_temp.details.${degree.id}`, null, null)}
                        </div>
                      ))}
                    {errors.education && errors.education.degrees && (
                      <div className="request-form__custom-error has-error">
                        <div className="ant-form-explain">{errors.education.degrees}</div>
                      </div>
                    )}
                  </div>
                  <BaseHint
                    className={classnames('base-hint--for-full-width-form-box', {
                      'request-form__hint--special-top': !values.education.proof_of_knowledge
                    })}
                  >
                    {t('request.form.hints.professionalOrAcademicDegreeFiles')}
                  </BaseHint>
                </div>
                {values.education && isAnyDegreeSelected(values.education.degrees_temp) && (
                  <div className="row">
                    {renderUploadBox({
                      name: 'professional_or_academic_degree_files',
                      title: t('request.form.uploadDocuments.professionalOrAcademicDegreeFiles'),
                      conditions: t('request.form.uploadDocuments.fileDefaults'),
                      type: FILE_UPLOAD_TYPES.PROFESSIONAL_OR_ACADEMIC_DEGREE_FILE,
                      maxFileSize,
                      isRenewal: isRenewal,
                      config: {
                        pdfOnly: false
                      }
                    })}
                  </div>
                )}
                <div className="row request-form__professional-education-group">
                  <div>
                    <h4 className="heading heading--subsection">{t('request.form.education.professionalKnowledge')}</h4>
                  </div>

                  <div>
                    {createCheckbox(
                      'education.has_predefined_certificates',
                      values.education.has_predefined_certificates,
                      (value, fieldName) => cleanFieldsAndFiles(fieldName),
                      t('request.form.education.hasPredefinedCertificates')
                    )}
                    {values.education.has_predefined_certificates && (
                      <div className="row">
                        <div className="col-12">
                          {createSelect({
                            name: 'education.predefined_certificates',
                            label: t('request.form.education.predefinedCertificates'),
                            options: filterInactiveMetadataForOptions({
                              options: predefined_certificates.data,
                              requestValues: request.education.predefined_certificates
                            }),
                            multiple: true,
                            placeholder: t('request.form.common.selectPlaceholder')
                          })}
                          <div className="request-form--with-hint">
                            {renderUploadBox({
                              name: 'predefined_certificate_files',
                              title: t('request.form.uploadDocuments.predefinedCertificateFiles'),
                              conditions: t('request.form.uploadDocuments.fileDefaults'),
                              type: FILE_UPLOAD_TYPES.PREDEFINED_CERTIFICATE_FILE,
                              maxFileSize,
                              isRenewal: isRenewal,
                              config: {
                                pdfOnly: false
                              }
                            })}
                            <BaseHint>{t('request.form.hints.predefinedCertificateFiles')}</BaseHint>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    {createCheckbox(
                      'education.has_additional_certificates',
                      values.education.has_additional_certificates,
                      (value, fieldName) => cleanFieldsAndFiles(fieldName),
                      t('request.form.education.hasAdditionalCertificates')
                    )}
                    {values.education.has_additional_certificates && (
                      <div className="row">
                        <div className="col-12">
                          <div className="request-form--with-hint">
                            <FastField
                              component={BaseTextArea}
                              name="education.additional_certificates"
                              value={values.education.additional_certificates}
                              data-cypress="additional_certificates"
                            />
                            <BaseHint
                              hints={[
                                t('request.form.hints.additionalCertificateFiles1'),
                                t('request.form.hints.additionalCertificateFiles2')
                              ]}
                            />
                          </div>
                          <div className="request-form--with-hint">
                            {renderUploadBox({
                              name: 'additional_certificate_files',
                              title: t('request.form.uploadDocuments.additionalCertificateFiles'),
                              conditions: t('request.form.uploadDocuments.fileDefaults'),
                              type: FILE_UPLOAD_TYPES.ADDITIONAL_CERTIFICATE_FILE,
                              maxFileSize,
                              isRenewal: isRenewal,
                              config: {
                                pdfOnly: false
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    {createCheckbox(
                      'education.has_employer_confirmation',
                      values.education.has_employer_confirmation,
                      (value, fieldName) => cleanFieldsAndFiles(fieldName),
                      t('request.form.education.hasEmployerConfirmation')
                    )}

                    {values.education.has_employer_confirmation && (
                      <div className="row">
                        <div className="col-12">
                          <div className="request-form--with-hint">
                            {renderUploadBox({
                              name: 'employer_confirmation_files',
                              title: t('request.form.uploadDocuments.employerConfirmationFiles'),
                              conditions: t('request.form.uploadDocuments.fileDefaults'),
                              type: FILE_UPLOAD_TYPES.EMPLOYER_CONFIRMATION_FILE,
                              maxFileSize,
                              isRenewal: isRenewal,
                              config: {
                                pdfOnly: false
                              }
                            })}
                            <BaseHint>
                              <Trans i18nKey="request.form.hints.employerConfirmation">
                                <a
                                  href="https://www.regservices.ch/?download=7462"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="link--underlined"
                                >
                                  Employer Confirmation
                                </a>
                              </Trans>
                            </BaseHint>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {errors.education &&
                    errors.education.has_predefined_or_additional_or_employer &&
                    touched.education &&
                    touched.education.has_predefined_certificates &&
                    touched.education.has_additional_certificates &&
                    touched.education.has_employer_confirmation && (
                      <div className="request-form__custom-error has-error">
                        <div className="ant-form-explain">
                          {errors.education.has_predefined_or_additional_or_employer}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </BaseBox>
          ) : (
            <AcademicDegrees {...props} banner summary />
          )}
        </>
      );

    case PROOF_OF_CODE_OF_CONDUCT:
      return (
        <>
          {/* Proof of code of conduct */}
          {isRenewal && (
            <>
              <BaseBox box className="base-summary__section code-of-conduct">
                <div className="row request-form__professional-education-group">
                  <div className="request-form--with-hint request-form--conduct-training">
                    <BaseHint>{t('request.summary.renewalBannerCodeOfConductHint')}</BaseHint>
                  </div>
                </div>
                {t('request.summary.renewalBannerCodeOfConductFirst')}
                {dateValid}
                {t('request.summary.renewalBannerCodeOfConductSecond')}
              </BaseBox>
            </>
          )}
          <BaseBox
            borderless
            data-cypress="request-form-box-code-of-conduct"
            className={classnames('base-summary__section code-of-conduct--secondary', {
              'code-of-conduct--renewal': isRenewal
            })}
            lifted
          >
            <div className="form-container">
              <BaseBoxHeading className="request-wrapper__main-heading">
                {t('request.summary.headings.KnowhowOfTheCode')}
                <br />
                {t('request.summary.headings.rulesFinsa')}
              </BaseBoxHeading>
              {isRenewal && (
                <>
                  <p className="code-of-conduct__subtitle">{t('request.summary.yourPreviousInformation')}</p>
                  <SummaryInput name={t('request.summary.selectedTrainings')}>
                    {request.education.finsa_certificates
                      .map(item => finsa_certificates.data.filter(data => data.id === item))
                      .flat()
                      .map(elem => (
                        <p key={elem.id}>{elem.name}</p>
                      ))}
                  </SummaryInput>
                  <SummaryInput name={t('request.summary.uploadedCertificates')}>
                    <SummaryFilesList
                      files={request.finsa_certificate_files.filter(
                        item =>
                          new Date(
                            getEarliestDate([
                              request.request_submission_date,
                              request.last_renewal_submission_date,
                              request.last_amendment_submission_date
                            ])
                          ) -
                            new Date(item.created_at) >
                          1
                      )}
                      download
                    />
                  </SummaryInput>
                </>
              )}
              <div className="row request-form__professional-education-group">
                <div>
                  {isRenewal ? (
                    <>
                      <div className="code-of-conduct__checkbox">
                        {createCheckbox(
                          'education.has_finsa_certificates',
                          true,
                          (value, fieldName) => cleanFieldsAndFiles(fieldName),
                          t('request.form.education.hasFinsaCertificates')
                        )}
                      </div>
                      <p className="code-of-conduct__info">{t('request.form.education.hasFinsaCertificates')}</p>
                    </>
                  ) : (
                    createCheckbox(
                      'education.has_finsa_certificates',
                      values.education.has_finsa_certificates,
                      (value, fieldName) => cleanFieldsAndFiles(fieldName),
                      t('request.form.education.hasFinsaCertificatesInitial')
                    )
                  )}
                  {values.education.has_finsa_certificates && (
                    <div className="row">
                      <div className="col-12">
                        {createSelect({
                          name: 'education.finsa_certificates',
                          label: '',
                          options: filterInactiveMetadataForOptions({
                            options: finsa_certificates.data,
                            requestValues: request.education.finsa_certificates
                          }),
                          placeholder: t('request.form.common.selectPlaceholder'),
                          multiple: true
                        })}
                        <div className="request-form--with-hint request-form--conduct-training">
                          {renderUploadBox({
                            name: 'finsa_certificate_files',
                            title: t('request.form.uploadDocuments.finsaCertificatesFiles'),
                            conditions: t('request.form.uploadDocuments.fileDefaults'),
                            type: FILE_UPLOAD_TYPES.FINSA_CERTIFICATE_FILE,
                            maxFileSize,
                            isRenewal: isRenewal,
                            isCodeOfConduct: true,
                            config: {
                              pdfOnly: false
                            }
                          })}
                          <BaseHint>{t('request.form.hints.finsaCertificate')}</BaseHint>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {errors.education &&
                  errors.education.has_finsa_checked &&
                  touched.education &&
                  touched.education.has_finsa_certificates && (
                    <div className="request-form__custom-error has-error">
                      <div className="ant-form-explain">{errors.education.has_finsa_checked}</div>
                    </div>
                  )}
              </div>
            </div>
          </BaseBox>
        </>
      );

    case COMPANY_INFORMATION:
      return (
        <>
          {/* Company information */}
          {editForm ? (
            <FieldArray
              name="companies"
              render={arrayHelpers => (
                <div>
                  {values.companies &&
                    values.companies.map((company, index) => (
                      <CompanyInformation
                        key={`${company}-${index}`}
                        borderless
                        isRenewal={isRenewal}
                        {...{
                          values,
                          countries,
                          ombudsmanOptions: filterInactiveMetadataForOptions({
                            options: ombudsman_options.data,
                            requestValues: request.companies[0].ombudsman_offices
                          }),
                          renderUploadBox,
                          FILE_UPLOAD_TYPES,
                          index,
                          cleanFieldsAndFiles,
                          formikHelpers: arrayHelpers,
                          saveStep
                        }}
                      />
                    ))}
                </div>
              )}
            />
          ) : (
            <BuisnessInformation {...props} summary banner isRenewal={isRenewal} />
          )}
        </>
      );
    case SUMMARY:
      const renderFieldsOfActivity = (
        <BaseBox borderless className="base-summary__section">
          <FieldsOfActivityList fields_of_activity={fields_of_activity} data={props.values} summary />
        </BaseBox>
      );
      return (
        <>
          {/* Summary */}
          <BasicInformation {...props} summary title />
          {renderFieldsOfActivity}
          <AcademicDegrees {...props} summary />
          <CodeOfConduct {...props} summary />
          <BuisnessInformation {...props} summary title />
          {/* additional recipients of decision */}
          {isAmendment && !isRenewal && (
            <BaseBox borderless className="base-summary__comment mt-16">
              <BaseBoxHeading>{t('request.summary.additionalRecipients')}</BaseBoxHeading>
              <Form className="form-container request-details__additional-recipients">
                <div className="row request-form--with-hint">
                  <div className="col-12">
                    <h4 className="heading heading--extra-light">{t('request.summary.additionalRecipientsLabel')}</h4>
                    {createInput('additional_recipients')}
                  </div>
                  <BaseHint className="base-hint--for-full-width-form-box">
                    {t('request.form.hints.additionalRecipients')}
                  </BaseHint>
                </div>
              </Form>
            </BaseBox>
          )}
          {isRenewal && (
            <BaseBox box className="base-summary__section additional_recipients-section">
              <Form className="form-container request-details__additional-recipients">
                {createInput('additional_recipients')}
              </Form>
            </BaseBox>
          )}
        </>
      );
    default:
      return null;
  }
};

const mapStateToProps = state => ({ user: state.user.details });

export default connect(mapStateToProps)(Step);
