import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseBox, BaseBoxHeading, BaseButtonFill, BaseButtonOutline, SummaryWrapper } from 'bxcommon';
import { Formik } from 'formik';
import { RequestContext } from 'bxcommon/context/Request.context';
import { createInput, useValidation, isFormEditable } from 'bxcommon/helpers/form.helper';
import {
  additionalRecipientsValidationSchema,
  detailsInitialValidationSchema
} from '../RequestForm/RequestFormValidation';
import { isPaymentProceededSuccessfully } from '../../common/common.helpers';

export const AdditionalInformation = props => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const { request, formRef, updateRequestField } = useContext(RequestContext);

  const [validationSchema] = useValidation(detailsInitialValidationSchema());
  const initialValues = {
    comment: request.comment || '',
    additional_recipients: request.additional_recipients || '',
    payment: {
      method: 1,
      voucher_code: '',
      express_process: false,
      accepted_additional_fee_agreement: false,
      status: request.payment ? request.payment.status : null
    }
  };

  const [showAdditionalRecipientsEditionBox, setShowAdditionalRecipientsEditionBoxFlag] = useState(false);
  const submitAdditionalRecipientsChange = async ({ additional_recipients }) => {
    try {
      await props.updateAdditionalRecipientsWhenClientAdvisorApproved({
        new_additional_recipients: additional_recipients,
        case_id: request.case_id
      });
      props.successToast(
        common('request.details.additionalRecipientEmails.successMessage'),
        common('successToastHeading')
      );
      setShowAdditionalRecipientsEditionBoxFlag(false);
    } catch (_) {
      props.errorToast(common('request.details.additionalRecipientEmails.errorMessage'), common('errorToastHeading'));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      ref={formRef}
      onSubmit={values => updateRequestField({ ...values })}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <SummaryWrapper heading={t('request.summary.headings.additionalInformation')} {...props}>
          <BaseBox className="base-summary__section" borderless data-cypress="payment">
            <BaseBoxHeading>{t('request.summary.headings.payments')}</BaseBoxHeading>
            {isPaymentProceededSuccessfully(values.payment) ? (
              <h4 className="heading heading--extra-light">{t('request.summary.paymentAlreadyMade')}</h4>
            ) : (
              '-'
            )}
          </BaseBox>
          <BaseBox borderless className="base-summary__section">
            <BaseBoxHeading className="base-summary__subheading">
              {t('request.summary.additionalRecipients')}
            </BaseBoxHeading>
            <div>
              {showAdditionalRecipientsEditionBox ? (
                <Formik
                  initialValues={{ additional_recipients: request.additional_recipients }}
                  onSubmit={submitAdditionalRecipientsChange}
                  validationSchema={additionalRecipientsValidationSchema(true)}
                  validateOnChange={true}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="change-recipients-form">
                      {createInput('additional_recipients')}
                      <div className="change-recipients-form__buttons">
                        <BaseButtonOutline
                          type="button"
                          small
                          onClick={() => setShowAdditionalRecipientsEditionBoxFlag(false)}
                        >
                          {common('request.details.additionalRecipientEmails.cancelBtn')}
                        </BaseButtonOutline>
                        <BaseButtonFill type="submit" small className="ml-16">
                          {common('request.details.additionalRecipientEmails.submitBtn')}
                        </BaseButtonFill>
                      </div>
                    </form>
                  )}
                </Formik>
              ) : (
                <div>
                  <h4 className="heading heading--extra-light">
                    {request.additional_recipients ? (
                      <>
                        <div className="heading__description">{t('request.summary.email')}</div>
                        <div className="heading__value">{request.additional_recipients}</div>
                      </>
                    ) : (
                      t('request.summary.noAdditionalRecipients')
                    )}
                  </h4>
                  {!showAdditionalRecipientsEditionBox && !request.additional_recipients && (
                    <div className="additional-recipients-change-button mt-16">
                      <BaseButtonOutline
                        onClick={() => setShowAdditionalRecipientsEditionBoxFlag(true)}
                        small
                        iconLeft
                        icon="mail"
                        data-cypress="details-request-change-button"
                      >
                        {common('request.details.changeAdditionalRecipientEmails')}
                      </BaseButtonOutline>
                    </div>
                  )}
                </div>
              )}
            </div>
          </BaseBox>
          <BaseBox borderless className="base-summary__section" data-cypress="summary-comment">
            <BaseBoxHeading>
              {t('request.summary.headings.comments')}{' '}
              {isFormEditable(request.status) && common('request.form.common.optional')}
            </BaseBoxHeading>
            <h4 className="heading heading--extra-light">{values.comment === '' ? '-' : values.comment}</h4>
          </BaseBox>
        </SummaryWrapper>
      )}
    </Formik>
  );
};
