import React from 'react';
import PropTypes from 'prop-types';
import {FastField, Form, Formik} from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { BaseBox, BaseBoxHeading, BaseButtonFill, BaseHint, BaseRadioGroup } from 'bxcommon';
import { createCleanFields, createInput, requiredStringValidator } from 'bxcommon/helpers/form.helper';

export const PublicRegistrySearch = ({onSubmit, loading}) => {
  const { t } = useTranslation();

  const initialValues = {
    search_type: 1,
    first_name: '',
    last_name: '',
    case_id: ''
  };

  const validation = (id) => {
    return Yup.string().when('search_type', {
      is: (searchType) => searchType === id,
      then: requiredStringValidator(),
      otherwise: Yup.string().notRequired()
    });
  };

  const validationSchema = Yup.object().shape({
    first_name: validation(1),
    last_name: validation(1),
    case_id: validation(2)
  }, ['search_type']);

  const publicRegistrySearchTypes = [
    { id: 1, name: t('publicRegistry.searchByName') },
    { id: 2, name: t('publicRegistry.searchByRegistrationId') }
  ];

  const cleanFields = createCleanFields({
    1: [
      {key: 'case_id', value: ''},
    ],
    2: [
      {key: 'first_name', value: ''},
      {key: 'last_name', value: ''},
    ]
  });

  return (
    <BaseBox>
      <BaseBoxHeading className="public-registry__search-heading">
        {t('publicRegistry.search.heading')}
      </BaseBoxHeading>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({values, setFieldValue}) => (
          <Form className="form-container">
            <FastField
              component={BaseRadioGroup}
              name="search_type"
              options={publicRegistrySearchTypes}
              value={values.search_type}
              onChange={(value) => cleanFields(false, value, setFieldValue)}
              data-cypress="search_type"
            />
            <div className="row">
              <div className="col-10">
                { values.search_type === 1 &&
                  <div className="row public-registry__search--with-hint">
                    <div className="col-6">
                      {createInput('first_name', t('publicRegistry.search.first_name'))}
                    </div>
                    <div className="col-6">
                      {createInput('last_name', t('publicRegistry.search.last_name'))}
                    </div>
                    <span>
                      <BaseHint>
                        {t('publicRegistry.search.hint')}
                      </BaseHint>
                    </span>
                  </div>
                }
                { values.search_type === 2 &&
                  <div className="row public-registry__search--with-hint">
                    {createInput('case_id', t('publicRegistry.search.case_id'))}
                  </div>
                }
                { values.search_type &&
                  <div className="row">
                    <div className="col-4">
                      <BaseButtonFill
                        type="submit"
                        full
                        loading={loading}
                        data-cypress="public-registry-submit-button"
                      >
                        {t('publicRegistry.search.button')}
                      </BaseButtonFill>
                    </div>
                  </div>
                }
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </BaseBox>
  )
};

PublicRegistrySearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
