import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { EMPLOYMENT_TYPE } from 'bxcommon/constants/companies.constant';
import { BaseBox, BaseBoxHeading, SummaryInput, summaryValuesIterator } from 'bxcommon';
import { searchForLabel } from 'bxcommon/helpers/form.helper';
import AppConfig from 'bxcommon/providers/AppConfig';
import PropTypes from 'prop-types';

export const PublicRegistryResult = props => {
  const { t } = useTranslation();
  const {
    countries,
    ombudsmanOffices,
    fieldsOfActivity,
    result,
    searchCompleted,
    predefinedCertificates,
    degreeData,
    finsaCertificates
  } = props;

  function displayCompanyName(company) {
    return company.type_of_employment === EMPLOYMENT_TYPE.SELF_EMPLOYED
      ? t('request.summary.selfEmployed')
      : company.name;
  }

  if (result.length) {
    return result.map((result, index) => (
      <BaseBox data-cypress="public-registry-result" key={index}>
        <h2 className="heading heading--subsection heading--bold" data-cypress="public-registry-result-heading">
          {result.case_id} - {result.first_name} {result.last_name}
        </h2>
        <SummaryInput name={t('publicRegistry.result.dateOfEntry')} data-cypress="date-of-entry">
          {/*// TODO change to proper date */}
          {moment(result.request_approval_date).format(AppConfig.dotsDateFormat)}
        </SummaryInput>

        <SummaryInput name={t('request.form.jobDescription.fields_of_activity')} data-cypress="fields-of-activity">
          {summaryValuesIterator(fieldsOfActivity, result.fields_of_activity)}
        </SummaryInput>

        <div className="public-registry__education">
          <BaseBoxHeading>{t('request.summary.headings.publicRegistryEducation')}</BaseBoxHeading>

          {result.education && result.education.degrees && (
            <SummaryInput name={t('request.summary.headings.professionalOrAcademicDegree')} data-cypress="degrees">
              {Object.entries(result.education.degrees).map(([degree_id, degree_text], index) => {
                return (
                  <div key={index} className="mb-def">
                    {searchForLabel(degreeData, parseInt(degree_id))}: {degree_text}
                  </div>
                );
              })}
            </SummaryInput>
          )}

          {(result.education.predefined_certificates.length > 0 ||
            result.education.additional_certificates.length > 0) && (
            <SummaryInput
              name={t('request.summary.headings.professionalKnowledge')}
              data-cypress="professional-knowledge"
            >
              {result.education.predefined_certificates.length > 0 && (
                <div>
                  <div className="mb-def">{t('request.summary.predefinedCertificates')}:</div>
                  <div className="mb-def2x pl-def2x">
                    {summaryValuesIterator(predefinedCertificates, result.education.predefined_certificates)}
                  </div>
                </div>
              )}
              {result.education.additional_certificates.length > 0 && (
                <div>
                  <div className="mb-def">{t('request.summary.additionalCertificates')}:</div>
                  <div className="mb-def2x pl-def2x">{result.education.additional_certificates}</div>
                </div>
              )}
            </SummaryInput>
          )}

          {(result.education.finsa_certificates.length > 0 || result.education.other_education.length > 0) && (
            <SummaryInput
              name={t('request.summary.headings.codeOfConductTraining')}
              data-cypress="code-of-conduct-trainings"
            >
              {result.education.finsa_certificates && result.education.finsa_certificates.length > 0 && (
                <div>
                  <div className="mb-def">{t('request.summary.codeOfConductTrainings')}:</div>
                  <div className="mb-def2x pl-def2x">
                    {summaryValuesIterator(finsaCertificates, result.education.finsa_certificates)}
                  </div>
                </div>
              )}
              {result.education.other_education && result.education.other_education.length > 0 && (
                <div>
                  <div className="mb-def">{t('request.summary.otherTrainings')}:</div>
                  <div className="mb-def2x pl-def2x">{result.education.other_education}</div>
                </div>
              )}
            </SummaryInput>
          )}
        </div>

        <BaseBoxHeading>{t('request.form.companyInformation.heading')}</BaseBoxHeading>
        {result.companies &&
          result.companies.length > 0 &&
          result.companies.map((company, index) => (
            <div key={index}>
              <h4>
                {t('request.summary.headings.businessInformationSubheader')} {displayCompanyName(company)}
              </h4>
              <SummaryInput name={t('request.form.companyInformation.company_name')} data-cypress="company-name">
                {displayCompanyName(company)}
              </SummaryInput>
              <SummaryInput name={t('publicRegistry.result.address')}>
                <>
                  <span data-cypress="address-line-1">{company.address_line_1}</span>
                  <br />
                  <span data-cypress="address-line-2">{company.address_line_2}</span>
                  <br />
                  <span data-cypress="postcode-city">
                    {company.postcode} {company.city}
                  </span>
                  <br />
                  <span data-cypress="country">{searchForLabel(countries, company.country)}</span>
                </>
              </SummaryInput>

              <SummaryInput name={t('request.form.jobDescription.job_function')} data-cypress="job-function">
                {company.job_function}
              </SummaryInput>
              <SummaryInput name={t('request.form.jobDescription.job_position')} data-cypress="job-position">
                {company.job_position}
              </SummaryInput>

              <SummaryInput name={t('request.summary.headings.associatedOmbudsman')} data-cypress="job-position">
                {summaryValuesIterator(ombudsmanOffices, company.ombudsman_offices)}
              </SummaryInput>
            </div>
          ))}

        {result.education.proof_of_knowledge_declaration && (
          <div>
            <BaseBoxHeading className="base-summary__subheading">
              {t('request.summary.headings.proofOfKnowledge')}
            </BaseBoxHeading>
            {t('request.summary.proofOfKnowledgeSentence')}
          </div>
        )}
      </BaseBox>
    ));
  } else if (searchCompleted && !result.length) {
    return (
      <BaseBox>
        <p className="copy" data-cypress="public-registry-empty-result">
          {t('publicRegistry.noResultFound')}
        </p>
      </BaseBox>
    );
  } else {
    return null;
  }
};

PublicRegistryResult.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      iso_code: PropTypes.string.isRequired
    })
  ),
  financialInstruments: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    )
  }),
  result: PropTypes.arrayOf(PropTypes.object),
  searchCompleted: PropTypes.bool
};
