import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppConfig from 'bxcommon/providers/AppConfig';
import { Formik } from 'formik';
import { BaseBox, BaseBoxHeading, BaseButtonFill, BaseButtonOutline, SummaryInput, SummaryWrapper } from 'bxcommon';
import { RequestContext } from 'bxcommon/context/Request.context';
import { createInput, filterDialCode, searchForLabel } from 'bxcommon/helpers/form.helper';
import { SummaryFilesList } from 'bxcommon/components/SummaryHelpers/SummaryFiles';
import { newEmailValidationSchema } from '../RequestForm/RequestFormValidation';
import { changeEmail } from 'bxcommon/misc/requests';
import moment from 'moment';

export const BasicInformation = props => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');
  const {
    request,
    resources: { countries, dialcodes }
  } = useContext(RequestContext);
  const { summary, isRenewal, title, banner } = props;

  const [showEmailEditionBox, setShowEmailEditionBoxFlag] = useState(false);
  const submitEmailChange = async ({ new_email }) => {
    const emailChangePayload = { new_email, case_id: request.case_id };
    try {
      await changeEmail(emailChangePayload);
      props.successToast(common('request.details.emailChange.successMessage'), common('successToastHeading'));
    } catch (_) {
      props.errorToast(common('request.details.emailChange.errorMessage'), common('errorToastHeading'));
    } finally {
      setShowEmailEditionBoxFlag(false);
    }
  };

  const data = summary ? props.values : request;

  const renderHeader = () => {
    if (title && summary) {
      return t('request.summary.headings.summary');
    }
    if (title && !summary) {
      return t('request.summary.headings.basicInformation');
    }
    if (banner) {
      return t('');
    }
  };

  return (
    <SummaryWrapper heading={renderHeader()} {...props}>
      {banner && isRenewal ? (
        <BaseBox box className="base-summary__section">
          {t('request.summary.renewalBanner')}
        </BaseBox>
      ) : null}
      <BaseBox borderless className="base-summary__section">
        <BaseBoxHeading box className="base-summary__subheading">
          {t('request.summary.headings.accountInformation')}
        </BaseBoxHeading>
        <ul className="base-summary__content">
          <SummaryInput name={t('request.form.personalInformation.first_name')} data-cypress="summary-first-name">
            {data.first_name || props.user.first_name}
          </SummaryInput>
          <SummaryInput name={t('request.form.personalInformation.last_name')} data-cypress="summary-last-name">
            {data.last_name || props.user.last_name}
          </SummaryInput>
          <SummaryInput name={t('request.form.personalInformation.email')} data-cypress="summary-email">
            {data.email || props.user.email}
          </SummaryInput>
          {AppConfig.approvedStatuses.includes(request.status) && !isRenewal && (
            <div className="mt-16">
              {!showEmailEditionBox && (
                <SummaryInput>
                  <BaseButtonOutline
                    onClick={() => setShowEmailEditionBoxFlag(true)}
                    small
                    iconLeft
                    icon="mail"
                    data-cypress="details-request-change-button"
                  >
                    {common('request.details.changeEmail')}
                  </BaseButtonOutline>
                </SummaryInput>
              )}
              {showEmailEditionBox && (
                <li>
                  <p>
                    <b>{common('request.details.emailChange.header')}</b>
                  </p>
                  <ol className="hints-list">
                    <li>{common('request.details.emailChange.hints.1')}</li>
                    <li>{common('request.details.emailChange.hints.2')}</li>
                    <li>{common('request.details.emailChange.hints.3')}</li>
                    <li>{common(`request.details.emailChange.hints.${props.user.is_admin ? '5' : '4'}`)}</li>
                  </ol>
                  <Formik
                    initialValues={{ new_email: '' }}
                    onSubmit={submitEmailChange}
                    validationSchema={newEmailValidationSchema()}
                    validateOnChange={true}
                  >
                    {({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} className="change-email-form">
                        {createInput('new_email')}
                        <div className="change-email-form__buttons">
                          <BaseButtonOutline type="button" small onClick={() => setShowEmailEditionBoxFlag(false)}>
                            {common('request.details.emailChange.cancelBtn')}
                          </BaseButtonOutline>
                          <BaseButtonFill type="submit" small className="ml-16">
                            {common('request.details.emailChange.submitBtn')}
                          </BaseButtonFill>
                        </div>
                      </form>
                    )}
                  </Formik>
                </li>
              )}
            </div>
          )}
        </ul>
      </BaseBox>
      <BaseBox borderless className="base-summary__section">
        <BaseBoxHeading className="base-summary__subheading">
          {t('request.summary.headings.personalInformation')}
        </BaseBoxHeading>
        <ul className="base-summary__content">
          <SummaryInput name={t('request.summary.address')}>
            {(data.address_line_1 || data.address_line_2) && (
              <span className="base-summary__address">
                <span className="base-summary--margin" data-cypress="summary-address-line-1">
                  {data.address_line_1}
                </span>
                <span className="base-summary--margin" data-cypress="summary-address-line-2">
                  {data.address_line_2}
                </span>
              </span>
            )}
          </SummaryInput>
          <SummaryInput
            name={`${t('request.form.common.postcode')} / ${t('request.form.common.city')}`}
            data-cypress="summary-city"
          >
            {(data.postcode || data.city) && (
              <>
                {data.postcode} {data.city}
              </>
            )}
          </SummaryInput>
          <SummaryInput name={t('request.form.common.countryOfDomicile')} data-cypress="summary-country">
            {searchForLabel(countries, data.country)}
          </SummaryInput>
          <SummaryInput
            name={t('request.form.personalInformation.alternative_email')}
            data-cypress="summary-alternative-email"
          >
            {data.alternative_email}
          </SummaryInput>
          <SummaryInput name={t('request.form.personalInformation.phone_number')} data-cypress="summary-phone-number">
            {(data.phone_number_dial_code || data.phone_number) && (
              <>
                {filterDialCode(
                  searchForLabel(dialcodes, data.phone_number_dial_code, {
                    label: 'dial',
                    value: 'id'
                  })
                )}{' '}
                {data.phone_number}
              </>
            )}
          </SummaryInput>
          <SummaryInput
            name={t('request.form.personalInformation.work_phone_number')}
            data-cypress="summary-work-phone-number"
          >
            {(data.work_phone_number_dial_code || data.work_phone_number) && (
              <>
                {filterDialCode(
                  searchForLabel(dialcodes, data.work_phone_number_dial_code, {
                    label: 'dial',
                    value: 'id'
                  })
                )}{' '}
                {data.work_phone_number}
              </>
            )}
          </SummaryInput>
          <SummaryInput name={t('request.form.personalInformation.date_of_birth')}>
            {moment(data.date_of_birth).format(AppConfig.defaultDateFormat) || '-'}
          </SummaryInput>
          <SummaryInput name={t('request.form.personalInformation.country_of_citizenship')}>
            {searchForLabel(countries, data.country_of_citizenship) || '-'}
          </SummaryInput>
          <SummaryInput name={t('request.form.personalInformation.additional_country_of_citizenship')}>
            {searchForLabel(countries, data.additional_country_of_citizenship) || '-'}
          </SummaryInput>
          <SummaryInput name={t('request.form.personalInformation.place_of_origin')}>
            {data.place_of_origin || '-'}
          </SummaryInput>
          <SummaryInput name={t('request.summary.copyOfPassportOrId')}>
            <SummaryFilesList files={data.passport_files} download />
          </SummaryInput>
          <SummaryInput name={t('request.summary.cv')}>
            <SummaryFilesList files={data.cv_files} download />
          </SummaryInput>
          <SummaryInput name={t('request.summary.criminalRecord')}>
            <SummaryFilesList files={data.criminal_record_extract_files} download />
          </SummaryInput>
        </ul>
      </BaseBox>
    </SummaryWrapper>
  );
};
