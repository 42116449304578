import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import store from '../../store';
import { clearForm, simpleLogin, updateRequestField, USER_ACTIONS } from 'bxcommon/store/actions';
import BaseLogin from 'bxcommon/components/BaseLogin/BaseLogin';
import { hydrateAdvisorList } from '../../store';
import errorService, { LOGIN_ERRORS } from 'bxcommon/helpers/errors.helper';


const UserLogin = ({simpleLogin, updateRequestField, hydrateAdvisorList}) => {
  const { t: common } = useTranslation('common');

  const warning = useCallback((error) => {
   errorService.handleErrorToast(error, LOGIN_ERRORS);
  }, []);

  const hydrateInitRegister = async(credentials) => {
    await simpleLogin(credentials);
    const list = await hydrateAdvisorList();

    if (list.length) {
      updateRequestField(list[0]);
    }

    store.dispatch({
      type: USER_ACTIONS.TOGGLE_LOGIN,
      payload: true
    })
  };

  return <BaseLogin
    isAdvisor
    login={hydrateInitRegister}
    warning={warning}
    heading={common('login.heading')}
  />;
};

export default connect(null, {
  simpleLogin,
  hydrateAdvisorList,
  updateRequestField,
  clearForm
})(UserLogin);
