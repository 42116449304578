import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestContext } from 'bxcommon/context/Request.context';
import { BaseBox, BaseBoxHeading, SummaryInput, summaryValuesIterator, SummaryWrapper } from 'bxcommon';
import { searchForLabel } from 'bxcommon/helpers/form.helper';
import { SummaryFilesList } from 'bxcommon/components/SummaryHelpers/SummaryFiles';
import { Checkbox } from 'antd';
import { FieldsOfActivityList } from './FieldsOfActivityList';

export const CareerInformation = props => {
  const { t } = useTranslation();
  const { summary } = props;

  const {
    request,
    resources: { education: degrees, predefined_certificates, finsa_certificates, fields_of_activity }
  } = useContext(RequestContext);

  const data = summary ? props.values : request;

  return (
    <SummaryWrapper heading={t('request.summary.headings.careerInformation')} {...props}>
      <BaseBox borderless className="base-summary__section">
        <FieldsOfActivityList summary data={data} fields_of_activity={fields_of_activity} />
        <BaseBoxHeading className="base-summary__subheading">
          {t('request.summary.headings.professionalOrAcademicDegree')}
        </BaseBoxHeading>
        {data.education &&
          data.education.degrees &&
          Object.entries(data.education.degrees).map(([degree_type, degree_detail]) => {
            return (
              <SummaryInput key={degree_type} name={searchForLabel(degrees.data, parseInt(degree_type))}>
                {degree_detail}
              </SummaryInput>
            );
          })}
        <SummaryInput name={t('request.summary.professionalOrAcademicDegreeFiles')}>
          <SummaryFilesList files={data.professional_or_academic_degree_files} download />
        </SummaryInput>

        {data.education.has_predefined_certificates && (
          <div className="row request-details__professional-knowledge request-details--column">
            <div className="request-details__professional-knowledge-group">
              <Checkbox
                className="request-details__checkbox--disabled"
                name="predefined_certificates"
                checked={true}
                disabled={true}
                data-cypress="predefined_certificates"
              >
                {t('request.form.education.hasPredefinedCertificates')}:
              </Checkbox>
              <div className="request-details__professional-knowledge-group--nested">
                {summaryValuesIterator(predefined_certificates.data, data.education.predefined_certificates)}
                <SummaryInput name={t('request.summary.uploadedCertificates')}>
                  <SummaryFilesList files={data.predefined_certificate_files} download />
                </SummaryInput>
              </div>
            </div>
          </div>
        )}

        {data.education.has_additional_certificates && (
          <div className="row request-details__professional-knowledge">
            <div className="request-details__professional-knowledge-group">
              <Checkbox
                className="request-details__checkbox--disabled"
                name="additional_certificates"
                checked={true}
                disabled={true}
                data-cypress="predefined_certificates"
              >
                {t('request.form.education.hasAdditionalCertificates')}:
              </Checkbox>
              <div className="request-details__professional-knowledge-group--nested">
                <span>{data.education.additional_certificates}</span>
                <SummaryInput name={t('request.summary.uploadedCertificates')}>
                  <SummaryFilesList files={data.additional_certificate_files} download />
                </SummaryInput>
              </div>
            </div>
          </div>
        )}

        {data.education.has_employer_confirmation && (
          <div className="row request-details__professional-knowledge">
            <div className="request-details__professional-knowledge-group">
              <Checkbox
                className="request-details__checkbox--disabled"
                name="employer_confirmation"
                checked={true}
                disabled={true}
                data-cypress="predefined_certificates"
              >
                {t('request.form.education.hasEmployerConfirmation')}:
              </Checkbox>
              <div className="request-details__professional-knowledge-group--nested">
                <SummaryInput name={t('request.summary.uploadedCertificates')}>
                  <SummaryFilesList files={data.employer_confirmation_files} download />
                </SummaryInput>
              </div>
            </div>
          </div>
        )}

        {data.education.has_finsa_certificates && (
          <div className="row request-details__professional-knowledge">
            <div className="request-details__professional-knowledge-group">
              <Checkbox
                className="request-details__checkbox--disabled"
                name="finsa_certificates"
                checked={true}
                disabled={true}
                data-cypress="predefined_certificates"
              >
                {t('request.form.education.hasFinsaCertificates')}:
              </Checkbox>
              <div className="request-details__professional-knowledge-group--nested">
                {summaryValuesIterator(finsa_certificates.data, data.education.finsa_certificates)}
                <SummaryInput name={t('request.summary.uploadedCertificates')}>
                  <SummaryFilesList files={data.finsa_certificate_files} download />
                </SummaryInput>
              </div>
            </div>
          </div>
        )}

        {data.education.other_education && (
          <div className="row request-details__professional-knowledge">
            <div className="request-details__professional-knowledge-group">
              <Checkbox
                className="request-details__checkbox--disabled"
                name="other_education"
                checked={true}
                disabled={true}
                data-cypress="predefined_certificates"
              >
                {t('request.form.education.hasOtherEducation')}:
              </Checkbox>
              <div className="request-details__professional-knowledge-group--nested">
                <span>{data.education.other_education}</span>
                <SummaryInput name={t('request.summary.uploadedCertificates')}>
                  <SummaryFilesList files={data.proof_of_code_of_conduct_training_files} download />
                </SummaryInput>
              </div>
            </div>
          </div>
        )}
      </BaseBox>
    </SummaryWrapper>
  );
};
