import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FastField, Form, Formik, Field } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { isPaymentProceededSuccessfully } from '../../common/common.helpers';
import { PAYMENT_METHODS } from 'bxcommon/constants/payments.constant';
import { RequestContext } from 'bxcommon/context/Request.context';
import { updateElectronicDelivery } from 'bxcommon/helpers/common.helpers';
import { ModalContext } from 'bxcommon/context/Modal.context';
import { createInput, createCheckbox, isFormEditable } from 'bxcommon/helpers/form.helper';
import { additionalRecipientsValidationSchema } from '../RequestForm/RequestFormValidation';
import {
  BaseBox,
  BaseBoxHeading,
  BaseHint,
  BaseTextArea,
  BaseButtonOutline,
  BaseButtonFill,
  BaseRadio
} from 'bxcommon';

import './Confirmations.scss';

export const Confirmations = props => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');
  const {
    request,
    updateRequestField,
    formRef,
    resources: { payment_methods }
  } = useContext(RequestContext);

  const { openConfirmModal } = useContext(ModalContext);
  const { validationSchema, isRenewal } = props;

  const regularPayment = request.payment ? request.payment.status : null;
  const amendmentPayment = request.amendment && request.amendment.payment ? request.amendment.payment.status : null;
  const isExpressPaymentRequired = Boolean(request.express_payment_needed);

  const initialConfirmations = {
    not_been_convicted: false,
    no_criminal_record: false,
    no_under_prohibition: false,
    not_registered_in_other_office: false,
    all_information_correct: false,
    aware_of_obligations: false,
    electronic_delivery_allowed: true
  };
  const renewalConfirmations = {
    not_been_convicted: false,
    no_criminal_record: false,
    no_under_prohibition: false,
    all_information_correct: false,
    aware_of_obligations: false,
    electronic_delivery_allowed: true
  };
  const getSessionStorageEmail = JSON.parse(sessionStorage.getItem('regsevices-form-values-additional-email')) || '';
  const getSessionStorageFirstName = JSON.parse(sessionStorage.getItem('regsevices-form-values-firstname'));
  const getSessionStorageLastName = JSON.parse(sessionStorage.getItem('regsevices-form-values-lastname'));

  const initialValues = {
    confirmations: isRenewal ? renewalConfirmations : initialConfirmations,
    comment: '',
    approvals: {
      terms_of_use: false,
      rules_of_registration_office: false,
      list_of_fees: false
    },
    additional_recipients:
      isRenewal && getSessionStorageEmail ? getSessionStorageEmail : request.additional_recipients || '',
    payment: {
      method: 1,
      voucher_code: '',
      express_process: isRenewal ? isExpressPaymentRequired : false,
      accepted_additional_fee_agreement: false,
      status: request.amendment ? amendmentPayment : regularPayment,
      express_payment_needed: isExpressPaymentRequired
    }
  };
  const electronicDeliveryModal = e => {
    let result = updateElectronicDelivery(openConfirmModal, updateRequestField, t, { target: { checked: !e } });
    if (result === false) {
      formRef.current.setFieldValue('confirmations.electronic_delivery_allowed', false, false);
    }
  };

  const uploadAdditionalEmailSessionStorage = additional_recipients => {
    sessionStorage.setItem('regsevices-form-values-additional-email', JSON.stringify(additional_recipients));
  };
  const uploadExpressAdditionalFeeAgreement = value => {
    sessionStorage.setItem('regservices-form-values-accepted_additional_fee_agreement', JSON.stringify(value));
  };
  const uploadExpressProcess = useCallback(value => {
    sessionStorage.setItem('regservices-form-values-express_process', JSON.stringify(value));
    uploadExpressAdditionalFeeAgreement(false);
  }, []);

  useEffect(() => {
    uploadExpressProcess(false);
  }, [uploadExpressProcess]);

  useEffect(() => {
    const form = formRef.current;
    if (!getSessionStorageEmail && request.additional_recipients) {
      uploadAdditionalEmailSessionStorage(request.additional_recipients);
    }

    return () => {
      uploadAdditionalEmailSessionStorage(form.state.values.additional_recipients);
    };
  }, [formRef, getSessionStorageEmail, request.additional_recipients]);

  const isChecked = (values, type) => values.payment.method && values.payment.method === type;

  const createRadio = (type, values, disabled = false) => {
    const item = payment_methods.data.find(i => i.id === type);

    return (
      <Field
        component={BaseRadio}
        checked={isChecked(values, type)}
        name="payment.method"
        disabled={disabled}
        value={item.id}
        data-cypress="type-of-payment"
      >
        {item.name}
      </Field>
    );
  };

  const [showAdditionalRecipientsEditionBox, setShowAdditionalRecipientsEditionBoxFlag] = useState(false);
  const submitAdditionalRecipientsChange = async ({ additional_recipients }) => {
    try {
      await props.updateAdditionalRecipientsWhenClientAdvisorApproved({
        new_additional_recipients: additional_recipients,
        case_id: request.case_id
      });
      props.successToast(
        common('request.details.additionalRecipientEmails.successMessage'),
        common('successToastHeading')
      );
      setShowAdditionalRecipientsEditionBoxFlag(false);
    } catch (_) {
      props.errorToast(common('request.details.additionalRecipientEmails.errorMessage'), common('errorToastHeading'));
    }
  };

  const feeLink = (
    <a
      href=" https://www.regservices.ch/?download=25337"
      rel="noopener noreferrer"
      target="_blank"
      className="fee-link"
    >
      List of Fees
    </a>
  );

  return (
    <Formik
      initialValues={initialValues}
      ref={formRef}
      onSubmit={values => updateRequestField({ ...values })}
      validationSchema={validationSchema}
      className="confirmations-form"
    >
      {({ values }) => (
        <div className="confirmations">
          {/* confirmations list of checkboxes */}
          {(isFormEditable(request.status) || isRenewal) && (
            <BaseBox borderless className="base-summary__comment" data-cypress="summary-confirmations">
              <BaseBoxHeading>{t('request.summary.headings.confirmations')}</BaseBoxHeading>
              <div>
                <h4 className="heading heading--extra-light">
                  I,{' '}
                  <b>
                    {isRenewal ? getSessionStorageFirstName : request.first_name}{' '}
                    {isRenewal ? getSessionStorageLastName : request.last_name}
                  </b>{' '}
                  {t('request.summary.headings.confirmation')}
                </h4>
              </div>
              <div className="row request-details--column">
                <div className="col-12">
                  {createCheckbox(
                    'confirmations.not_been_convicted',
                    values.confirmations.not_been_convicted,
                    null,
                    t('request.summary.confirmations.notBeenConvicted')
                  )}
                  {createCheckbox(
                    'confirmations.no_criminal_record',
                    values.confirmations.no_criminal_record,
                    null,
                    t('request.summary.confirmations.noCriminalRecord')
                  )}
                  {createCheckbox(
                    'confirmations.no_under_prohibition',
                    values.confirmations.no_under_prohibition,
                    null,
                    t('request.summary.confirmations.noUnderProhibition')
                  )}
                  {!isRenewal &&
                    createCheckbox(
                      'confirmations.not_registered_in_other_office',
                      values.confirmations.not_registered_in_other_office,
                      null,
                      t('request.summary.confirmations.notRegisteredInOtherOffice')
                    )}
                  {createCheckbox(
                    'confirmations.aware_of_obligations',
                    values.confirmations.aware_of_obligations,
                    null,
                    t('request.summary.confirmations.awareOfObligations')
                  )}
                  {createCheckbox(
                    'confirmations.all_information_correct',
                    values.confirmations.all_information_correct,
                    null,
                    t('request.summary.confirmations.allInformationCorrect')
                  )}
                  {createCheckbox(
                    'confirmations.electronic_delivery_allowed',
                    values.confirmations.electronic_delivery_allowed,
                    electronicDeliveryModal,
                    t('request.summary.confirmations.electronicDeliveryAllowed')
                  )}
                </div>
                <div className="col-12">
                  <p className="copy copy--small request-details__fees">
                    <Trans i18nKey="request.summary.additionalFees">
                      <a
                        href="https://www.post.ch/en/business/a-z-of-subjects/incamail"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link--underlined"
                      >
                        IncaMail
                      </a>
                    </Trans>
                  </p>
                </div>
              </div>
            </BaseBox>
          )}

          {/* additional recipients of decision */}
          <BaseBox borderless className="base-summary__comment mt-16">
            <BaseBoxHeading>{t('request.summary.additionalRecipients')}</BaseBoxHeading>
            {isFormEditable(request.status) || isRenewal ? (
              <Form className="form-container request-details__additional-recipients">
                <div className="row request-form--with-hint">
                  <div className="col-12">
                    <h4 className="heading heading--extra-light">{t('request.summary.additionalRecipientsLabel')}</h4>
                    {createInput('additional_recipients')}
                  </div>
                  <BaseHint className="base-hint--for-full-width-form-box">
                    {t('request.form.hints.additionalRecipients')}
                  </BaseHint>
                </div>
              </Form>
            ) : (
              <div>
                {showAdditionalRecipientsEditionBox ? (
                  <Formik
                    initialValues={{ additional_recipients: request.additional_recipients }}
                    onSubmit={submitAdditionalRecipientsChange}
                    validationSchema={additionalRecipientsValidationSchema(true)}
                    validateOnChange={true}
                  >
                    {({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} className="change-recipients-form">
                        {createInput('additional_recipients')}
                        <div className="change-recipients-form__buttons">
                          <BaseButtonOutline
                            type="button"
                            small
                            onClick={() => setShowAdditionalRecipientsEditionBoxFlag(false)}
                          >
                            {common('request.details.additionalRecipientEmails.cancelBtn')}
                          </BaseButtonOutline>
                          <BaseButtonFill type="submit" small className="ml-16">
                            {common('request.details.additionalRecipientEmails.submitBtn')}
                          </BaseButtonFill>
                        </div>
                      </form>
                    )}
                  </Formik>
                ) : (
                  <div>
                    <div>{request.additional_recipients || t('request.summary.noAdditionalRecipients')}</div>
                    {!showAdditionalRecipientsEditionBox && (
                      <div className="additional-recipients-change-button mt-16">
                        <BaseButtonOutline
                          onClick={() => setShowAdditionalRecipientsEditionBoxFlag(true)}
                          small
                          iconRight
                          icon="pencil"
                          data-cypress="details-request-change-button"
                        >
                          {common('request.details.changeAdditionalRecipientEmails')}
                        </BaseButtonOutline>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </BaseBox>

          {/* urgent applications for additional fee information */}
          {(!isRenewal || isExpressPaymentRequired) && (
            <BaseBox borderless className="base-summary__section mt-16" data-cypress="urgent-applications">
              <BaseBoxHeading>{t('request.summary.headings.urgentApplications')}</BaseBoxHeading>
              {isRenewal && isExpressPaymentRequired && (
                <div
                  onChange={() => {
                    if (!values.payment.accepted_additional_fee_agreement) {
                      values.payment.method = PAYMENT_METHODS.CREDIT_CARD;
                    }
                    values.payment.express_process = true;
                  }}
                >
                  <BaseBox box className="base-summary__section code-of-conduct renewal-fee-agreement">
                    <Trans i18nKey="request.summary.estimationExpress.renewalAdditionalFeeAgreement">{feeLink}</Trans>
                  </BaseBox>
                  {createCheckbox(
                    'payment.accepted_additional_fee_agreement',
                    values.payment.accepted_additional_fee_agreement,
                    uploadExpressAdditionalFeeAgreement,
                    t('request.summary.estimationExpress.renewalAdditionalFee')
                  )}
                </div>
              )}
              {!isRenewal && (
                <div
                  className="row request-form--with-hint request-details--column"
                  onChange={() => {
                    if (!values.payment.express_process) {
                      values.payment.method = PAYMENT_METHODS.CREDIT_CARD;
                    }
                    values.payment.accepted_additional_fee_agreement = false;
                  }}
                >
                  <div>
                    {createCheckbox(
                      'payment.express_process',
                      values.payment.express_process,
                      uploadExpressProcess,
                      <Trans i18nKey="request.summary.estimationExpress.additionalFeeAgreement">{feeLink}</Trans>
                    )}
                  </div>
                  {values.payment.express_process && (
                    <div className="additional-fee-checkbox">
                      {createCheckbox(
                        'payment.accepted_additional_fee_agreement',
                        values.payment.accepted_additional_fee_agreement,
                        uploadExpressAdditionalFeeAgreement,
                        t('request.summary.estimationExpress.urgentApplicationAgreement')
                      )}
                    </div>
                  )}
                </div>
              )}
            </BaseBox>
          )}

          {/* payments/billing information */}

          <BaseBox borderless className="base-summary__section mt-16" data-cypress="payment">
            <BaseBoxHeading>{t('request.summary.headings.paymentMethod')}</BaseBoxHeading>
            {!isPaymentProceededSuccessfully(values.payment) ||
            !isPaymentProceededSuccessfully(values.amendment.payment) ? (
              <div
                className="row request-form--with-hint request-details--column"
                onChange={() => {
                  values.payment.voucher_code = '';
                }}
              >
                <div>{t('request.summary.paymentBox')}</div>
                <div className="radio-group">
                  <label>{createRadio(PAYMENT_METHODS.CREDIT_CARD, values)}</label>
                  <label>
                    {createRadio(
                      PAYMENT_METHODS.VOUCHER_CODE,
                      values,
                      values.payment.express_process || isExpressPaymentRequired
                    )}
                  </label>
                </div>
                {values.payment.method === PAYMENT_METHODS.VOUCHER_CODE && (
                  <div>{createInput('payment.voucher_code')}</div>
                )}
                <BaseHint className="base-hint--for-full-width-form-box">
                  {t('request.form.hints.paymentMethod')}
                </BaseHint>
              </div>
            ) : (
              <>
                <h4 className="heading heading--extra-light">{t('request.summary.paymentAlreadyMade')}</h4>
              </>
            )}
          </BaseBox>

          {/* comments */}

          <BaseBox borderless className="base-summary__comment mt-16" data-cypress="summary-comment">
            <BaseBoxHeading>
              {t('request.summary.headings.comments')}{' '}
              {(isFormEditable(request.status) || isRenewal) && common('request.form.common.optional')}
            </BaseBoxHeading>
            {isFormEditable(request.status) || isRenewal ? (
              <div>
                <div>{common('request.summary.commentBox')}</div>
                <FastField
                  component={BaseTextArea}
                  name="comment"
                  value={values.comment}
                  data-cypress="additional_certificates"
                />
              </div>
            ) : (
              <h4 className="heading heading--extra-light">{values.comment}</h4>
            )}
          </BaseBox>

          {/* accept terms */}

          {(isFormEditable(request.status) || isRenewal) && (
            <BaseBox borderless className="base-summary__comment mt-16" data-cypress="summary-approvals">
              <BaseBoxHeading>{t('request.summary.accept')}</BaseBoxHeading>
              <div className="row">
                <div className="col-12">
                  {createCheckbox(
                    'approvals.terms_of_use',
                    values.approvals.terms_of_use,
                    null,
                    <div>
                      <a
                        href="https://www.regservices.ch/en/terms-of-use/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link--underlined"
                      >
                        {t('request.summary.approvals.termsOfUse')}
                      </a>
                    </div>
                  )}
                  {createCheckbox(
                    'approvals.rules_of_registration_office',
                    values.approvals.rules_of_registration_office,
                    null,
                    <div>
                      <a
                        href="https://www.regservices.ch/en/regulations-registrationoffice/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link--underlined"
                      >
                        {t('request.summary.approvals.rulesOfRegistrationOffice')}
                      </a>
                    </div>
                  )}
                  {createCheckbox(
                    'approvals.list_of_fees',
                    values.approvals.list_of_fees,
                    null,
                    <div>
                      <a
                        href="https://www.regservices.ch/en/regulations-registrationoffice/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link--underlined"
                      >
                        {t('request.summary.approvals.listOfFees')}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </BaseBox>
          )}
        </div>
      )}
    </Formik>
  );
};
