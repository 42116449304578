import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { login } from 'bxcommon/store/actions';
import BaseLogin from 'bxcommon/components/BaseLogin/BaseLogin';
import errorService, { LOGIN_ERRORS } from 'bxcommon/helpers/errors.helper';


const AdminLogin = ({login}) => {
  const { t: common } = useTranslation('common');

  const warning = useCallback((error) => {
    errorService.handleErrorToast(error, LOGIN_ERRORS)
  }, []);

  return <BaseLogin
    isAdvisor
    isAdmin
    login={(data) => login(data, true)}
    warning={warning}
    heading={common('login.admin.heading')}
  />;
};

export default connect(null, { login })(AdminLogin);
