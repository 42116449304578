import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import classnames from 'classnames'

import { history } from 'bxcommon/BxBrowserRouter';

import './AdvisorHeader.scss';

import { AppHeaderAdmin, AppHeaderDefault, BaseButtonFill } from 'bxcommon';
import { clearForm } from 'bxcommon/store/actions';

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  isAdmin: state.user.details.is_admin
});

const AdvisorHeader_= ({children, isAdmin, clearForm, hideControls}) => {
  const { t } = useTranslation();

  const goToForm = () => {
    clearForm();
    history.push('/request-form');
  }

  const header = (
    <div className={classnames('advisor-header', { 'advisor-header--admin': isAdmin && !hideControls })}>
      {(isAdmin && !hideControls) ? (
        <BaseButtonFill
          type="button"
          iconRight
          icon="add"
          onClick={goToForm}
          data-cypress="advisor-header-admin-add-new"
        >
          {t('header.createNew')}
        </BaseButtonFill>
      ) : (
        <div className="advisor-header__title">
          {t('header.name')}
        </div>
      )}
    </div>
  );

  return (
    <AppHeaderDefault
      isAdvisor
      hideControls={hideControls}
      adminHeader={<AppHeaderAdmin />}
      controls={header}
    >
      {children}
    </AppHeaderDefault>
  )
};


AdvisorHeader_.propTypes = {
  children: PropTypes.node.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  hideControls: PropTypes.bool
};

export const AdvisorHeader = connect(mapStateToProps, {clearForm})(AdvisorHeader_);
