import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { HalfPageLayout } from 'bxcommon/components/HalfPageLayout/HalfPageLayout';
import { BaseLoaderSpinner, ThankYouPage } from 'bxcommon';
import { checkRequestPaymentStatus } from 'bxcommon/store/actions';
import { PAYMENT_STATUSES } from 'bxcommon/constants/payments.constant';
import { successToast, warningToast } from 'bxcommon/store/actions';
import errorService from 'bxcommon/helpers/errors.helper';

import { AdvisorHeader } from '../AdvisorHeader/AdvisorHeader';
import './ThankYou.scss';

const ThankYouAdvisor = ({ history, match, checkRequestPaymentStatus, warningToast, successToast }) => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!match.params.caseId) {
      setLoading(false);
      return;
    }

    const checkPayment = () => {
      // TODO - PWCBX-60 change this to proper behavior and interval status check
      // setTimeout as a temp solution due to async hook between payrexx backend and BX backend
      setTimeout(async () => {
        try {
          const response = await checkRequestPaymentStatus(match.params.caseId);
          const paymentStatus = response.data.data.status;

          if (paymentStatus && paymentStatus === PAYMENT_STATUSES.SUCCESS) {
            successToast(
              common(`request.toasts.requestSubmittedMessage`),
              common(`request.toasts.requestSubmittedTitle`)
            );
          } else {
            warningToast(common('messages.failedPayment'));
            history.push('/request-form');
          }
        } catch (e) {
          console.log(e);
          errorService.showBackendErrors(e);
          return false;
        } finally {
          setLoading(false);
        }
      }, 4000);
    };

    checkPayment();
  }, [checkRequestPaymentStatus, common, history, match.params.caseId, warningToast, successToast]);

  const Content = () => (
    <div className="thank-you__loading thank-you--advisor">
      <BaseLoaderSpinner isComponent={true} large={true} />
      <p className="thank-you__loading-description">{t('request.thankYou.paymentPending')}</p>
    </div>
  );

  return (
    <AdvisorHeader>
      {loading && match.params.caseId ? (
        <HalfPageLayout rightContent={<Content />} />
      ) : (
        <ThankYouPage
          isAdvisor
          link={match.params.caseId ? `/request-details/${match.params.caseId}` : '/request-details'}
          description={t('request.thankYou.description')}
        />
      )}
    </AdvisorHeader>
  );
};

export default connect(null, { checkRequestPaymentStatus, successToast, warningToast })(ThankYouAdvisor);
