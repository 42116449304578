import React, { useContext, useEffect, useRef, useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AppConfig from 'bxcommon/providers/AppConfig';
import { AdvisorHeader } from '../AdvisorHeader/AdvisorHeader';
import { RequestStepWrapper } from 'bxcommon/components/RequestStepWrapper/RequestStepWrapper';
import { FormWrapper } from 'bxcommon/components/FormWrapper/FormWrapper';
import { Step } from './Step';
import { DEFAULT_COMPANY } from './CompanyInformation';
import { Confirmations } from './Confirmations';
import { Intro } from '../Intro/Intro';
import {
  activityValidationSchema,
  personalValidationSchema,
  professionalValidationSchema,
  codeOfConductValidationSchema,
  companyValidationSchema,
  formValidationSchema,
  detailsInitialValidationSchema,
  detailsRenewalValidationSchema
} from './RequestFormValidation';
import { FORM_VARIANT } from 'constants/form.constant';
import { STEP_VARIANT } from 'constants/steps.constant';
import { useValidation, mapDegrees, DEGREES_MAPPING_OPTIONS } from 'bxcommon/helpers/form.helper';
import { STATUS } from 'bxcommon/constants/status.constant';
import { registryInitCheck } from 'common/common.helpers';
import { RequestContext } from 'bxcommon/context/Request.context';
import { AMENDMENT_TYPE } from 'bxcommon/constants/status.constant';

import './RequestForm.scss';

const RequestForm = props => {
  const { user, match, history } = props;
  const { t } = useTranslation();
  const { request, updateRequestField, list, formRef } = useContext(RequestContext);
  const canReinitialise = useRef(true);
  const isAmendment = request.amendment && AppConfig.editStatuses.includes(request.amendment.status);
  const [intro, showIntro] = useState(isAmendment);
  // When you want test Renewal process on local environment isRenewal change to true
  const isRenewal = request.amendment && request.amendment.type === AMENDMENT_TYPE.RENEWAL;
  const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);
  const isUpdateRequire = request.status === STATUS.UPDATE_REQUIRED;

  useLayoutEffect(() => {
    if (
      request.case_id &&
      !AppConfig.editStatuses.includes(request.status) &&
      !(request.amendment && AppConfig.editStatuses.includes(request.amendment.status))
    ) {
      history.push('/request-details');
    }
  }, [history, request.case_id, request.status, request.amendment]);

  // Needed only for hydration data on entry straight to the /caseID url/  when in admin mode
  useEffect(() => {
    registryInitCheck(match, user, updateRequestField, list, request);
    // TODO: debug why after first POST we have page re-render
    // Because of a hydration delay if entered from direct URL we need to enable hydrating for a moment
    // and then in next tick disable reinitialise as it messes up with file upload flow (first POST).
    setTimeout(() => (canReinitialise.current = false));
  }, [list, match, user, request, updateRequestField]);

  useEffect(() => {
    if (!intro) {
      const companies = request.companies && request.companies.length > 0 ? request.companies : [DEFAULT_COMPANY];
      formRef.current.setFieldValue('companies', companies);
    }
  }, [request.companies, formRef, intro]);

  const onFormSubmit = () => {
    const values = formRef.current.state.values;
    values.education.degrees = mapDegrees(values.education.degrees_temp, DEGREES_MAPPING_OPTIONS.MAP);
  };

  const isCaseIdAndEmailSpecifiedInRequest = request.case_id && request.email;
  const adminModeNewRequest = user.is_admin && !isCaseIdAndEmailSpecifiedInRequest;
  const getSessionStorage =
    isRenewal && isSummaryCompleted && sessionStorage.getItem('regsevices-form-values')
      ? JSON.parse(sessionStorage.getItem('regsevices-form-values'))
      : false;
  const requestValues = getSessionStorage || request;
  const getSessionStorageEmail = JSON.parse(sessionStorage.getItem('regsevices-form-values-additional-email')) || '';

  const initialValues = {
    first_name: user.is_admin ? requestValues.first_name || '' : requestValues.first_name || user.first_name,
    last_name: user.is_admin ? requestValues.last_name || '' : requestValues.last_name || user.last_name,
    email: adminModeNewRequest ? '' : requestValues.email || user.email,

    // personal
    address_line_1: requestValues.address_line_1 || '',
    address_line_2: requestValues.address_line_2 || '',
    postcode: requestValues.postcode || '',
    city: requestValues.city || '',
    country: requestValues.country || '',
    alternative_email: requestValues.alternative_email || '',
    phone_number: requestValues.phone_number || '',
    phone_number_dial_code: requestValues.phone_number_dial_code || '',
    work_phone_number: requestValues.work_phone_number || '',
    work_phone_number_dial_code: requestValues.work_phone_number_dial_code || '',
    date_of_birth: requestValues.date_of_birth ? moment(requestValues.date_of_birth) : null,
    country_of_citizenship: requestValues.country_of_citizenship || '',
    additional_country_of_citizenship: requestValues.additional_country_of_citizenship || '',
    place_of_origin: requestValues.place_of_origin || '',

    // fields of activity
    fields_of_activity: requestValues.fields_of_activity || [],

    // education
    education: {
      // professional or academic degree
      degrees_temp: mapDegrees(requestValues.education.degrees || [], DEGREES_MAPPING_OPTIONS.DETAILED_MAP),

      // professional trainings && knowledge according to FinSA and other
      has_predefined_certificates: Boolean(requestValues.education.has_predefined_certificates),
      predefined_certificates: requestValues.education.predefined_certificates || [],
      has_additional_certificates: Boolean(requestValues.education.has_additional_certificates),
      additional_certificates: requestValues.education.additional_certificates || '',
      has_employer_confirmation: Boolean(requestValues.education.has_employer_confirmation),
      has_finsa_certificates: Boolean(requestValues.education.has_finsa_certificates),
      finsa_certificates: isRenewal && !isSummaryCompleted ? [] : requestValues.education.finsa_certificates || []
    },

    // company / job / ombudsman office
    companies:
      requestValues.companies && requestValues.companies.length > 0 ? requestValues.companies : [DEFAULT_COMPANY],

    // documents
    passport_files: requestValues.passport_files || [],
    cv_files: requestValues.cv_files || [],
    criminal_record_extract_files: requestValues.criminal_record_extract_files || [],
    professional_or_academic_degree_files: requestValues.professional_or_academic_degree_files || [],
    predefined_certificate_files: requestValues.predefined_certificate_files || [],
    additional_certificate_files: requestValues.additional_certificate_files || [],
    employer_confirmation_files: requestValues.employer_confirmation_files || [],
    finsa_certificate_files: isRenewal && !isSummaryCompleted ? [] : requestValues.finsa_certificate_files || [],
    proof_of_code_of_conduct_training_files: requestValues.proof_of_code_of_conduct_training_files || [],

    additional_recipients: isRenewal ? getSessionStorageEmail : requestValues.additional_recipients || ''
  };

  const { PAYMENTS_CONFIRMATIONS, PROOF_OF_CODE_OF_CONDUCT } = STEP_VARIANT;

  const steps = [
    t('request.summary.headings.personalInformation'),
    t('request.summary.headings.fieldsOfActivity'),
    t('request.summary.headings.professionalKnowledge'),
    t('request.summary.headings.proofOfCodeOfConduct'),
    t('request.summary.headings.businessInformation'),
    t('request.summary.headings.summary')
  ];
  const isInitial = !isAmendment && !isUpdateRequire;
  (isInitial || isRenewal) && steps.push(t('request.summary.headings.conclusion'));

  const { REQUEST_FORM } = FORM_VARIANT;
  const [activeStep, setActiveStep] = useState(1);
  const isFinalStep = activeStep === steps.length;
  const [editForm, setEditForm] = useState(!isRenewal);

  /* Previous version had different values, we need to check if finsa_certificate_files exists */
  const isCodeOfConduct = steps[activeStep - 1] === PROOF_OF_CODE_OF_CONDUCT;

  const [fields, submitFields] = useValidation(activityValidationSchema(user.is_admin));
  const [personal, submitPersonal] = useValidation(personalValidationSchema(user.is_admin));
  const [professional, submitProfessional] = useValidation(professionalValidationSchema(user.is_admin));
  const [codeOfConduct, submitcodeOfConduct] = useValidation(codeOfConductValidationSchema(user.is_admin));
  const [company, submitCompany] = useValidation(companyValidationSchema(user.is_admin));
  const [summary, submitSummary] = useValidation(isAmendment || !isInitial ? formValidationSchema(user.is_admin) : {});
  const [confirmations, submitConfirmations] = useValidation(
    isRenewal ? detailsRenewalValidationSchema() : detailsInitialValidationSchema()
  );

  const validations = [personal, fields, professional, codeOfConduct, company, summary, confirmations];
  const submitValidations = [
    submitPersonal,
    submitFields,
    submitProfessional,
    submitcodeOfConduct,
    submitCompany,
    submitSummary,
    submitConfirmations
  ];

  const currentValidationSchema = validations[activeStep - 1];

  return (
    <AdvisorHeader>
      <FormWrapper isAdvisor>
        <RequestStepWrapper
          adminRedirect="/request-details"
          finalRedirect={`/request-form/confirmation/${request.case_id}`}
          goBack={user.is_admin ? '/dashboard' : null}
          formRef={formRef}
          validationSubmit={submitValidations[activeStep - 1]}
          formVariant={REQUEST_FORM}
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isIntro={intro}
          isRenewal={isRenewal}
          isFinal={isFinalStep}
          setEditForm={setEditForm}
          editForm={editForm}
          isCodeOfConduct={isCodeOfConduct}
          setIsSummaryCompleted={setIsSummaryCompleted}
          isAdvisor
          isUpdateRequire={isUpdateRequire}
          {...props}
        >
          <>
            {isAmendment && intro && <Intro isAmendment={isRenewal ? false : isAmendment} showIntro={showIntro} />}
            {steps[activeStep - 1] !== PAYMENTS_CONFIRMATIONS && !intro && (
              <Formik
                enableReinitialize={canReinitialise.current}
                ref={formRef}
                initialValues={initialValues}
                onSubmit={() => onFormSubmit()}
                validationSchema={currentValidationSchema}
              >
                {({ values, errors, touched, fields }) => (
                  <div className="request-form__step">
                    <Step
                      activeStep={steps[activeStep - 1]}
                      values={values}
                      errors={errors}
                      touched={touched}
                      user={user}
                      editForm={editForm}
                      isRenewal={isRenewal}
                      isAmendment={isAmendment}
                    />
                  </div>
                )}
              </Formik>
            )}
            {steps[activeStep - 1] === PAYMENTS_CONFIRMATIONS && !intro && (
              <Confirmations validationSchema={currentValidationSchema} isRenewal={isRenewal} />
            )}
          </>
        </RequestStepWrapper>
      </FormWrapper>
    </AdvisorHeader>
  );
};

const mapStateToProps = state => ({ user: state.user.details });

export default connect(mapStateToProps)(RequestForm);
