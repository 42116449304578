import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from 'bxcommon/i18n';
import * as en from './locales/en';
import * as common from 'bxcommon/locales/en/common';

const resources = {
  en: {
    common: common.default,
    registry: en.default
  }
};

i18n.init({
  resources,
  ns: ['common', 'registry'],
  defaultNS: 'registry',
  lng: "en",
  interpolation: {
    escapeValue: false
  }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
