import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseBoxHeading, summaryValuesIterator } from 'bxcommon';

export const FieldsOfActivityList = props => {
  const { t } = useTranslation();
  const { fields_of_activity, data } = props;

  return (
    <div className="fields-of-activity-list">
      <BaseBoxHeading className="base-summary__subheading">
        {t('request.summary.headings.fieldsOfActivity')}
      </BaseBoxHeading>
      <div className="fields-of-activity-list__items" data-cypress="summary-fields-of-activity">
        {summaryValuesIterator(fields_of_activity.data, data.fields_of_activity)}
      </div>
    </div>
  );
};
