import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryWrapper, BaseBox } from 'bxcommon';
import { CompanyInformationDetails } from 'components/RequestDetails/CompanyInformationDetails';
import { RequestContext } from 'bxcommon/context/Request.context';

export const BuisnessInformation = props => {
  const { t } = useTranslation();
  const { summary, title, banner, isRenewal } = props;

  const { request } = useContext(RequestContext);
  const data = summary ? props.values : request;

  return (
    <SummaryWrapper heading={title ? t('request.summary.headings.companyInformation') : t('')} {...props}>
      {banner && isRenewal ? (
        <BaseBox box className="base-summary__section">
          {t('request.summary.renewalBanner')}
        </BaseBox>
      ) : null}
      {data.companies &&
        data.companies.length > 0 &&
        data.companies.map((company, index) => {
          return <CompanyInformationDetails key={index} company={company} />;
        })}
    </SummaryWrapper>
  );
};
