import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseBox, BaseBoxHeading, SummaryInput } from 'bxcommon';
import { RequestContext } from 'bxcommon/context/Request.context';
import { SummaryFilesList } from 'bxcommon/components/SummaryHelpers/SummaryFiles';

import './Summary.scss';

export const CodeOfConduct = props => {
  const { t } = useTranslation();
  const { summary } = props;
  const { request } = useContext(RequestContext);
  const data = summary ? props.values : request;

  return (
    <div className="code-of-conduct">
      <BaseBox borderless lifted>
        <BaseBoxHeading className="request-wrapper__main-heading">
          {t('request.summary.headings.KnowhowOfTheCode')}
          <br />
          {t('request.summary.headings.rulesFinsa')}
        </BaseBoxHeading>
        {data.education.has_finsa_certificates && (
          <div className="code-of-conduct__container">
            {t('request.form.education.hasFinsaCertificates')}:
            <SummaryInput name={t('request.summary.uploadedCertificates')}>
              <SummaryFilesList files={data.finsa_certificate_files} download />
            </SummaryInput>
          </div>
        )}
        {data.education.other_education && (
          <div className="code-of-conduct__container">
            {t('request.form.education.hasOtherEducation')}:<span>{data.education.other_education}</span>
            <SummaryInput name={t('request.summary.uploadedCertificates')}>
              <SummaryFilesList files={data.proof_of_code_of_conduct_training_files} download />
            </SummaryInput>
          </div>
        )}
      </BaseBox>
    </div>
  );
};
