import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseBox, BaseBoxHeading, SummaryInput } from 'bxcommon';
import { RequestContext } from 'bxcommon/context/Request.context';
import { SummaryFilesList } from 'bxcommon/components/SummaryHelpers/SummaryFiles';
import { searchForLabel } from 'bxcommon/helpers/form.helper';

import './Summary.scss';

export const AcademicDegrees = props => {
  const { t } = useTranslation();

  const {
    request,
    resources: { education: degrees }
  } = useContext(RequestContext);
  const { banner, summary } = props;
  const data = summary ? props.values : request;

  const renderDegrees = values =>
    Object.entries(values).map(([degree_type, degree_detail]) => (
      <div className="academic-degrees__container" key={degree_type}>
        <SummaryInput key={degree_type} name={searchForLabel(degrees.data, parseInt(degree_type))}>
          {degree_detail}
        </SummaryInput>
      </div>
    ));

  const renderDegree = () => {
    if (data.education && data.education.degrees) {
      if (Object.keys(data.education.degrees).length === 0) {
        return <div className="academic-degrees__container">-</div>;
      } else {
        return renderDegrees(data.education.degrees);
      }
    } else {
      if (data.education.degrees_temp) {
        if (Object.keys(data.education.degrees_temp.details).length === 0) {
          return <div className="academic-degrees__container">-</div>;
        } else {
          return renderDegrees(data.education.degrees_temp.details);
        }
      }
    }
  };

  return (
    <div className="academic-degrees">
      {banner && (
        <BaseBox box className="base-summary__section">
          {t('request.summary.renewalBanner')}
        </BaseBox>
      )}
      <BaseBox borderless lifted>
        <BaseBoxHeading className="request-wrapper__main-heading">
          {t('request.summary.academicDegrees')}
        </BaseBoxHeading>
        {renderDegree()}
        <BaseBoxHeading className="request-wrapper__main-heading">
          {t('request.summary.professionalKnowledge')}
        </BaseBoxHeading>
        {data.education.has_predefined_certificates && (
          <div className="academic-degrees__container">
            {t('request.form.education.hasPredefinedCertificates')}:
            <SummaryInput name={t('request.summary.uploadedCertificates')}>
              <SummaryFilesList files={data.predefined_certificate_files} download />
            </SummaryInput>
          </div>
        )}
        {data.education.has_additional_certificates && (
          <div className="academic-degrees__container">
            {t('request.form.education.hasAdditionalCertificates')}:
            <SummaryInput name={t('request.summary.uploadedCertificates')}>
              <SummaryFilesList files={data.additional_certificate_files} download />
            </SummaryInput>
          </div>
        )}
        {data.education.has_employer_confirmation && (
          <div className="academic-degrees__container">
            {t('request.form.education.hasEmployerConfirmation')}:
            <SummaryInput name={t('request.summary.uploadedCertificates')}>
              <SummaryFilesList files={data.employer_confirmation_files} download />
            </SummaryInput>
          </div>
        )}
      </BaseBox>
    </div>
  );
};
