import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { BaseButtonLink } from 'bxcommon';

import { AdvisorHeader } from '../AdvisorHeader/AdvisorHeader';

import './DashboardView.scss';

const DashboardView = ({history, isAdmin}) => {
  useEffect(() => {
    if (!isAdmin) {
      history.push('/');
    }
  }, [isAdmin, history]);

  const { t } = useTranslation();

  return (
    <AdvisorHeader>
      <div className="dashboard-view">
        <h1>{t('dashboard.title')}</h1>
        <p>
          <BaseButtonLink onClick={() => history.push('/list')}>
            {t('dashboard.description.0')}
          </BaseButtonLink>
          {t('dashboard.description.1')}
        </p>
      </div>
    </AdvisorHeader>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.user.details.is_admin,
});

export default connect(
  mapStateToProps,
  {}
)(DashboardView);
