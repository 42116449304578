import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseBox, BaseBoxHeading } from 'bxcommon';
import { RequestContext } from 'bxcommon/context/Request.context';
import className from 'classnames';
import { filterInactiveMetadataForOptions } from 'bxcommon/helpers/form.helper';
import './Summary.scss';

export const FieldsOfActivity = props => {
  const { t } = useTranslation();
  const { summary } = props;
  const {
    request,
    resources: { fields_of_activity }
  } = useContext(RequestContext);

  const data = summary ? props.values : request;
  const getCheckboxes = filterInactiveMetadataForOptions({
    options: fields_of_activity.data,
    requestValues: data.fields_of_activity
  });
  const checkedValues = data.fields_of_activity;

  return (
    <div className="fields-of-activity">
      <BaseBox box className="base-summary__section">
        {t('request.summary.renewalBanner')}
      </BaseBox>
      <BaseBox borderless lifted>
        <BaseBoxHeading className="request-wrapper__main-heading">
          {t('request.form.fieldsOfActivity.heading')}
        </BaseBoxHeading>
        {getCheckboxes.map(item => (
          <div
            key={item.id}
            className={className('fields-of-activity__item', {
              'fields-of-activity__item--active': checkedValues.includes(item.id)
            })}
          >
            <div className="fields-of-activity__point">
              <div className="fields-of-activity__check"></div>
            </div>
            <div className="fields-of-activity__value">{item.name}</div>
          </div>
        ))}
      </BaseBox>
    </div>
  );
};
